import { useCallback, useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Spinner from '../Spinner';
import ReactSlider from 'react-slider';

import getCroppedImg, { uploadCroppedImg } from './utils/CroppedImage';
import { resizeImages } from '../../utils/apiProduction';
import { updateDoc } from '../../utils/firestore';
import { getCollection, getId } from '../../utils/constants';
import PreviousCrops from './utils/PreviousCrops';

const CropAndSave = ({ data, cropData }) => {
	const [reload, setReload] = useState(true);
	const [saving, setSaving] = useState(false);
	const [s3Url, setS3Url] = useState(undefined);

	const [savingMsg, setSavingMsg] = useState('Saving');

	const [mainImage, setMainImage] = useState(undefined);

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(cropData.zoomDefault);

	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedPreviewUpdated, setCroppedPreviewUpdated] = useState(false);

	useEffect(() => {
		if (data && data.cropped && data.cropped.base) {
			setMainImage(data.cropped.base);
		}
	}, [data]);

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setS3Url('');
		setCroppedAreaPixels(croppedAreaPixels);
		setCroppedPreviewUpdated(false);
	}, []);

	const showCroppedImage = async () => {
		setS3Url('');
		setSaving(true);
		try {
			const _croppedImage = await getCroppedImg(mainImage, croppedAreaPixels);
			setCroppedImage(_croppedImage);
			setCroppedPreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
		setSaving(false);
	};

	const saveImageToAWS = async (image, variation) => {
		const holdingURL = await uploadCroppedImg(image, croppedAreaPixels);
		console.log('holdingURL', { holdingURL });
		setSavingMsg(`Saving ${cropData.type}_${variation}`);

		let filename = `${data.cm_id}_${cropData.type}.png`;
		if (variation > 0) {
			filename = `${data.cm_id}_${cropData.type}_${variation}.png`;
		}

		const resizeData = {
			url: holdingURL,
			fileName: filename,
			width: cropData.width,
			height: cropData.height,
		};

		const result = await resizeImages(resizeData);
		if (result && result.data) {
			setS3Url(result.data);
		} else {
			setS3Url('Failed,try again');
		}
	};

	const handleUploadCroppedImage = async () => {
		setS3Url('');
		try {
			setSaving(true);

			if (data.cropped.base) {
				await saveImageToAWS(data.cropped.base, 0);
			}
			if (data.cropped.color2) {
				await saveImageToAWS(data.cropped.color2, 2);
			}
			if (data.cropped.color3) {
				await saveImageToAWS(data.cropped.color3, 3);
			}
			if (data.cropped.color4) {
				await saveImageToAWS(data.cropped.color4, 4);
			}

			let _cropped = data.cropped;
			_cropped[cropData.type] = {
				x: crop.x,
				y: crop.y,
				zoom: zoom,
			};

			await updateDoc(getCollection(data), { cropped: _cropped }, getId(data));

			setSaving(false);
		} catch (e) {
			console.error(e);
		}
	};

	const reloadCrop = () => {
		setReload(false);
		setTimeout(() => {
			setReload(true);
		}, 250);
	};

	return (
		<div>
			<PreviousCrops data={data} setZoom={setZoom} setCrop={setCrop} cropData={cropData} reloadCrop={reloadCrop} />
			<div className="m-4 flex items-center space-x-2 justify-center ">
				<button className={`${croppedPreviewUpdated ? 'bg-blue-100' : 'bg-green-100 hover:bg-green-200'} rounded-xl px-4 py-2 shadow`} onClick={showCroppedImage}>
					{croppedPreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
				</button>

				<button className={`${!croppedPreviewUpdated ? 'bg-red-100' : 'bg-green-100 hover:bg-green-200'} rounded-xl px-4 py-2 shadow`} onClick={handleUploadCroppedImage}>
					{croppedPreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
				</button>

				<span className="mx-2 font-weight-bold text-success">{saving ? savingMsg : ''}</span>
				{saving && <Spinner />}
				{s3Url && <span>Saved to {s3Url}</span>}
			</div>

			<div className="m-4 flex items-center space-x-2 justify-center">
				<p>ZOOM SLIDER:</p>
				<div className="w-96">
					{reload && (
						<ReactSlider
							className="w-full h-2 relative"
							trackClassName="bg-blue-500 h-1 shadow"
							renderThumb={(props, state) => <div {...props} className="w-20 h-10 focus:outline-none cursor-pointer text-blue-500 font-bold">{`⬆️  ${state.valueNow}`}</div>}
							defaultValue={zoom}
							min={cropData.zoomMin}
							max={cropData.zoomMax}
							step={cropData.zoomStep}
							value={zoom}
							onChange={(changeEvent) => setZoom(changeEvent)}
						/>
					)}
				</div>
			</div>

			<div className="crop-wrapper flex justify-center">
				<div className={`crop-container-${cropData.type} bg-green-50`}>
					{reload && (
						<Cropper
							image={mainImage}
							minZoom={cropData.zoomMin}
							maxZoom={cropData.zoomMax}
							zoomSpeed={cropData.zoomSpeed}
							restrictPosition={false}
							crop={crop}
							zoom={zoom}
							aspect={cropData.aspect}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
							objectFit="contain"
						/>
					)}
				</div>
				<div className={`crop-preview-${cropData.type} bg-gray-700`}>
					<img style={{ position: 'absolute', top: 0, left: 0 }} className={`crop-preview-${cropData.type}`} src={croppedImage} alt="" />

					{(cropData.type === 'preview' || cropData.type === 'inventory') && (
						<img
							style={{ position: 'absolute', top: 0, left: 0 }}
							className={`crop-preview-${cropData.type}`}
							src={`https://ethemerals-media.s3.amazonaws.com/firebase_card_${cropData.type}.png`}
							alt=""
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default CropAndSave;
