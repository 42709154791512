import React, { useState } from 'react';
import { uploadBlob } from '../components/Crops/utils/CroppedImage';
import Spinner from '../components/Spinner';
import { resizeImages } from '../utils/apiProduction';

const UploadBG = ({ title, name }) => {
	const [uploading, setUploading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [holdingUrl, setHoldingUrl] = useState(undefined);
	const onFileChange = async (e) => {
		setUploading(true);
		try {
			const file = e.target.files[0];
			let url = await uploadBlob(file);
			setHoldingUrl(url);
			console.log(url);
		} catch (error) {
			console.log(error);
		}
		// uploadBlob;
		setUploading(false);
	};

	const onImgLoad = async ({ target: img }) => {
		const { offsetHeight, offsetWidth } = img;
		console.log(offsetHeight, offsetWidth);
		await uploadToAWS(offsetWidth, offsetHeight);
	};

	const uploadToAWS = async (width, height) => {
		setSaving(true);
		if (width > 100 && height > 100) {
			console.log('do it');

			const resizeData = {
				url: holdingUrl,
				fileName: `webapp/${name}.jpg`,
				width,
				height,
			};

			const result = await resizeImages(resizeData);
			if (result && result.data) {
				console.log(result.data);
			} else {
				console.log('error');
			}
		}
		setSaving(false);
	};

	return (
		<div className="py-4">
			<p>{title}:</p>
			<input style={{ width: '196px' }} className="ml-1 mb-1 text-xs" type="file" onChange={(e) => onFileChange(e)} />
			{uploading && <Spinner />}
			{saving && (
				<>
					<Spinner />
					<span className="text-2xl">Saving to AWS</span>
				</>
			)}
			{holdingUrl && <img onLoad={onImgLoad} src={holdingUrl} alt="" />}
		</div>
	);
};

function Backgrounds() {
	return (
		<>
			<div className="flex mt-2">
				<div className="p-4">
					<h2 className="pb-4">Upload Jpegs of any size</h2>
					<UploadBG title="Gateway" name="portal_bg" />
					<UploadBG title="Eternal Battle" name="battle_bg2" />
					<UploadBG title="Onsen" name="onsen_bg" />
				</div>
			</div>
		</>
	);
}

export default Backgrounds;
