import { useEffect, useState } from 'react';
import { usePets } from '../hooks/useCollections';
import { getCollection, getId } from '../utils/constants';
import { updateDoc } from '../utils/firestore';
import { mainclassToString, subclassToString } from '../utils/metadataUtils';
import EditColors from './Modals/Metadata/EditColors';
import EditCostume from './Modals/Metadata/EditCostume';
import EditField from './Modals/Metadata/EditField';
import EditPets from './Modals/Metadata/EditPets';
import EditSubclass from './Modals/Metadata/EditSubclass';

const HorizontalBreak = () => {
	return <hr className="border border-white mt-4"></hr>;
};

const CardDescriptionMetadata = ({ data }) => {
	const { pets } = usePets();
	const [meralPet, setMeralPet] = useState(undefined);
	const [metadata, setMetadata] = useState(undefined);
	const [isEditNameOpen, setIsEditNameOpen] = useState(false);
	const [isEditSetOpen, setIsEditSetOpen] = useState(false);
	const [isEditSubclassOpen, setIsEditSubclassOpen] = useState(false);
	const [isEditCostumeOpen, setIsEditCostumeOpen] = useState(false);
	const [isEditDesignerOpen, setIsEditDesignerOpen] = useState(false);
	const [isEditEyesOpen, setIsEditEyesOpen] = useState(false);
	const [isEditHairOpen, setIsEditHairOpen] = useState(false);
	const [isEditSkinOpen, setIsEditSkinOpen] = useState(false);
	const [isEditPetsOpen, setIsEditPetsOpen] = useState(false);

	useEffect(() => {
		if (metadata && metadata.pet) {
			if (pets) {
				pets.forEach((pet) => {
					if (metadata.pet === pet.baseId) {
						let petName = pet.metadata.name;
						if (pet.baseId === 31) {
							petName = 'Random Slime';
						}
						setMeralPet({
							name: petName,
							image: pet.images.fb_base,
						});
						return;
					}
				});
			}
		}
		return () => {
			setMeralPet(undefined);
		};
	}, [pets, metadata]);

	useEffect(() => {
		if (data && data.metadata) {
			setMetadata(data.metadata);
		}
	}, [data]);

	const toggleEditName = (open) => {
		setIsEditNameOpen(open);
	};

	const handleEditName = async (_coinName) => {
		if (_coinName.length > 16) {
			alert('Name Too Long, max 16 chars');
		} else {
			let newDoc = data;
			newDoc.metadata.coin = _coinName;
			await updateDoc(getCollection(data), newDoc, getId(data));
		}
	};

	const toggleEditSubclass = (open) => {
		setIsEditSubclassOpen(open);
	};

	const handleEditSubclass = async (_subclass) => {
		let newDoc = data;
		newDoc.metadata.class = parseInt(_subclass.class);
		newDoc.metadata.subclass = parseInt(_subclass.subclass);
		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const toggleEditSet = (open) => {
		setIsEditSetOpen(open);
	};

	const handleEditSet = async (_set) => {
		let set = parseInt(_set);
		if (set > 0 && set < 10) {
			let newDoc = data;
			newDoc.metadata.set = parseInt(set);
			await updateDoc(getCollection(data), newDoc, getId(data));
		} else {
			alert('out of bounds');
		}
	};

	const toggleEditCostume = (open) => {
		setIsEditCostumeOpen(open);
	};

	const handleEditCostume = async (_costume) => {
		let newDoc = data;
		newDoc.metadata.costume = _costume;
		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const toggleEditDesigner = (open) => {
		setIsEditDesignerOpen(open);
	};

	const handleEditDesigner = async (_designer) => {
		if (_designer.length > 16 || _designer.length < 3) {
			alert('Name Too Long, max 16 chars');
		} else {
			let newDoc = data;
			newDoc.metadata.designer = _designer;
			await updateDoc(getCollection(data), newDoc, getId(data));
		}
	};

	const toggleEditEyes = (open) => {
		setIsEditEyesOpen(open);
	};

	const handleEditEyes = async (_eyes) => {
		let newDoc = data;
		newDoc.metadata.eyes = _eyes;
		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const toggleEditHair = (open) => {
		setIsEditHairOpen(open);
	};

	const handleEditHair = async (_hair) => {
		let newDoc = data;
		newDoc.metadata.hair = _hair;
		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const toggleEditSkin = (open) => {
		setIsEditSkinOpen(open);
	};

	const handleEditSkin = async (_skin) => {
		let newDoc = data;
		newDoc.metadata.skin = _skin;
		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const toggleEditPets = (open) => {
		setIsEditPetsOpen(open);
	};

	const handleEditPets = async (_pet) => {
		let newDoc = data;
		newDoc.metadata.pet = parseInt(_pet);
		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	if (!metadata) {
		return <div>Loading...</div>;
	}

	let bgColor = '';
	if (metadata.class === 0) {
		bgColor = 'bg-blue-200';
	}
	if (metadata.class === 1) {
		bgColor = 'bg-red-200';
	}
	if (metadata.class === 2) {
		bgColor = 'bg-green-200';
	}

	return (
		<>
			<div className="p-2 mb-10 text-sm text-gray-700">
				<h4 className="text-xs my-4">Metadata</h4>
				<div className="grid grid-cols-3 items-start">
					<div>
						<h3>Nice Coin Name:</h3>
						<p onClick={toggleEditName} className="bg-white m-2 text-lg px-2 text-gray-900 cursor-pointer">
							{metadata.coin}
						</p>
					</div>
					<div>
						<h3>Gen:</h3>
						<p onClick={toggleEditSet} className="bg-white m-2 text-lg px-2 text-gray-900 cursor-pointer">
							{metadata.set.toString()}
						</p>
					</div>

					<div></div>
				</div>
				<HorizontalBreak />
				<div className="grid grid-cols-3 items-start">
					<div>
						<h3>Mainclass:</h3>
						<p onClick={toggleEditSubclass} className={`m-2 text-lg px-2 text-gray-900 cursor-pointer ${bgColor}`}>
							{mainclassToString(metadata.class)}
						</p>
					</div>
					<div>
						<h3>Subclass:</h3>
						<p onClick={toggleEditSubclass} className={`m-2 text-lg px-2 text-gray-900 cursor-pointer ${bgColor}`}>
							{subclassToString(metadata.subclass)}
						</p>
					</div>
					<div></div>
				</div>
				<HorizontalBreak />

				<div className="grid grid-cols-3 items-start">
					<div>
						<h3>Eyes:</h3>
						<p onClick={toggleEditEyes} className={`m-2 text-lg px-2 text-gray-900 cursor-pointer bg-white`}>
							{metadata.eyes}
						</p>
					</div>
					<div>
						<h3>Hair:</h3>
						<p onClick={toggleEditHair} className={`m-2 text-lg px-2 text-gray-900 cursor-pointer bg-white`}>
							{metadata.hair}
						</p>
					</div>
					<div>
						<h3>Skin:</h3>
						<p onClick={toggleEditSkin} className={`m-2 text-lg px-2 text-gray-900 cursor-pointer bg-white`}>
							{metadata.skin}
						</p>
					</div>
				</div>
				<HorizontalBreak />

				<div className="grid grid-cols-3 items-start">
					<div>
						<h3>Costume:</h3>
						<p onClick={toggleEditCostume} className="bg-white m-2 text-lg px-2 text-gray-900 cursor-pointer">
							{metadata.costume}
						</p>
					</div>

					<div>
						<h3>Designer/Artist:</h3>
						<p onClick={toggleEditDesigner} className="bg-white m-2 text-lg px-2 text-gray-900 cursor-pointer">
							{metadata.designer}
						</p>
					</div>
					<div></div>
				</div>
				<HorizontalBreak />

				<div className="grid grid-cols-3">
					<div>
						<h3>Pet:</h3>
						{meralPet ? <img className="w-44 h-44" src={meralPet.image} alt="" /> : <div className="w-44 h-44 bg-gray-800"></div>}
					</div>
					<div>
						<h3 className="text-gray-200">Pet Name</h3>
						<p className="m-2 text-lg px-2 text-gray-900">{meralPet ? meralPet.name : 'None Selected'}</p>
						<p onClick={toggleEditPets} className="bg-yellow-500 rounded-xl text-white shadow m-2 text-lg px-2 cursor-pointer hover:bg-yellow-300">
							Change Pet
						</p>
					</div>

					<div></div>
				</div>
				<HorizontalBreak />
			</div>
			{isEditNameOpen && <EditField field="Coin Name" toggle={toggleEditName} onSubmit={handleEditName} defaultValue={metadata.coin} />}
			{isEditSetOpen && <EditField field="Generation Number" toggle={toggleEditSet} onSubmit={handleEditSet} defaultValue={metadata.set} />}
			{isEditSubclassOpen && <EditSubclass toggle={toggleEditSubclass} onSubmit={handleEditSubclass} />}
			{isEditCostumeOpen && <EditCostume toggle={toggleEditCostume} onSubmit={handleEditCostume} />}
			{isEditDesignerOpen && <EditField field="Designer / Artist Name" toggle={toggleEditDesigner} onSubmit={handleEditDesigner} defaultValue={metadata.designer} />}
			{isEditEyesOpen && <EditColors toggle={toggleEditEyes} onSubmit={handleEditEyes} type={0} />}
			{isEditHairOpen && <EditColors toggle={toggleEditHair} onSubmit={handleEditHair} type={1} />}
			{isEditSkinOpen && <EditColors toggle={toggleEditSkin} onSubmit={handleEditSkin} type={2} />}
			{isEditPetsOpen && <EditPets toggle={toggleEditPets} onSubmit={handleEditPets} />}
		</>
	);
};

export default CardDescriptionMetadata;
