import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getUserFromEmail } from '../../utils/constants';
import { messageDiscord } from '../../utils/apiProduction';
import { useAuth } from '../../hooks/useAuth';

const ConfirmDiscord = ({ data, toggle }) => {
	const { user } = useAuth();
	const { register, handleSubmit } = useForm();
	const [loading, setLoading] = useState(false);
	const [clicked, setClicked] = useState(false);

	const onSubmit = async (form) => {
		setLoading(true);
		if (!clicked) {
			await submitMessageDiscord(form.text);
		}
		setClicked(true);
		setLoading(false);
		toggle(false);
	};

	const parseBoard = (_data) => {
		let board = '';
		let webhook;
		if (_data.board === 1) {
			board = 'design';
			webhook = process.env.REACT_APP_DISCORD_DESIGN;
		}
		if (_data.board === 2) {
			board = 'art';
			webhook = process.env.REACT_APP_DISCORD_ART;
		}
		if (_data.board === 3) {
			board = 'metadata';
			webhook = process.env.REACT_APP_DISCORD_METADATA;
		}

		return { board, webhook };
	};

	const submitMessageDiscord = async (text) => {
		let description = text;
		if (description.length < 1) {
			description = 'Updated!';
		}

		const { board, webhook } = parseBoard(data);

		let discordData = {
			webhook: webhook,
			title: `${data.name} ${data.symbol}`,
			url: `https://gen2.binktogether.com/${board}/${data.cm_id}`,
			fieldTitle: `Hello! from ${board.toUpperCase()} board`,
			cm_id: parseInt(data.cm_id),
			fieldBody: `a message from: ${getUserFromEmail(user.email)}`,
			description: description,
			image: data.images[0],
		};

		await messageDiscord(discordData);
	};

	return (
		<>
			<div className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div onClick={() => toggle(false)} className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div className="w-96 h-96 center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white">
					<div className="center w-max text-center">
						<div className="py-2">
							<p className="text-xl">Send This Message To Discord:</p>
							<img className="img-thumbnail mx-auto pt-2 mb-2" src={data.images[0]} alt="" width="200" height="100" />
							<p className="text-xs text-gray-600 text-left">OPTIONAL TEXT:</p>
							<textarea className="w-full bg-gray-200 p-2 shadow-inner" {...register('text')} />
						</div>
						<button onClick={() => toggle(false)} className="bg-gray-400 text text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
						<button onClick={handleSubmit(onSubmit)} className="bg-yellow-400 text text-bold px-4 py-2 my-2 shadow-lg hover:bg-yellow-300 transition duration-300">
							{loading ? <span>SENDING...</span> : 'SEND'}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default ConfirmDiscord;
