import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { createDoc } from '../utils/firestore';

const AddCandiate = ({ count, toggleListView }) => {
	const { register, handleSubmit, reset } = useForm();
	const [saving, setSaving] = useState(false);
	const formRef = useRef();

	const onSubmitAdd = async (data) => {
		if (data.symbol.length < 1 || data.name.length < 1) {
			return;
		}
		setSaving(true);

		let id = uuidv4();
		let timestamp = Date.now();

		await createDoc('candiates', id, {
			id: id,
			name: data.name,
			symbol: data.symbol,
			images: ['https://ethemerals-media.s3.amazonaws.com/coin_placeholder.jpg'],
			timestamp: timestamp,
			status: 0,
			list: 0,
			board: 0,
		});

		formRef.current.reset();
		reset({ name: '', symbol: '' });

		setSaving(false);
	};

	return (
		<>
			<form ref={formRef}>
				<div className="bg-gray-100 p-2 flex items-center text-xs sm:text-base">
					<div className="mr-4">
						<span>coin name</span>
						<input className="ml-2 w-36 p-2 bg-gray-300 shadow-inner border border-gray-400 text-black" {...register('name')} />
					</div>
					<div className="mr-4">
						<span>symbol</span>
						<input className="ml-2 w-16 p-2 bg-gray-300 shadow-inner border border-gray-400 text-black" {...register('symbol')} />
					</div>
					<div onClick={handleSubmit(onSubmitAdd)} className="px-4 py-2 bg-yellow-400 text-gray-900 border border-black hover:bg-yellow-200 cursor-pointer">
						Add
					</div>
					<div onClick={toggleListView} className="ml-8 px-4 py-2 bg-blue-400 text-gray-900 border border-black hover:bg-blue-200 cursor-pointer">
						Toggle View
					</div>
					<p className="px-2 text-green-800">
						Candiates Count: <span className="font-bold">{count ? count : '0'}</span>
					</p>

					{saving && <span className="px-2 text-red-600">SAVING...</span>}
				</div>
			</form>
		</>
	);
};

export default AddCandiate;
