import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import { createDoc } from '../../utils/firestore';

const AddPet = ({ count, toggleListView }) => {
	const { register, handleSubmit, reset } = useForm();
	const [saving, setSaving] = useState(false);
	const formRef = useRef();

	const onSubmitAdd = async (data) => {
		let rarity = parseInt(data.rarity);
		if (rarity < 1 || rarity > 5) {
			alert('rarity must be between 1 and 5');
			return;
		}

		setSaving(true);

		let id = uuidv4();
		let atk = 40 * rarity;
		let def = 30 * rarity;
		let spd = 30 * rarity;

		await createDoc('metadata_pets', id, {
			id,
			baseId: count,
			metadata: {
				baseId: count,
				name: data.name,
				rarity,
				atk,
				def,
				spd,
			},
			images: { fb_base: 'https://ethemerals-media.s3.amazonaws.com/coin_placeholder.jpg' },
		});

		formRef.current.reset();
		reset({ name: '', rarity: '' });

		setSaving(false);
	};

	return (
		<>
			<form ref={formRef}>
				<div className="bg-gray-100 p-2 flex items-center text-xs sm:text-base">
					<div className="mr-4">
						<span>pet name</span>
						<input className="ml-2 w-36 p-2 bg-gray-300 shadow-inner border border-gray-400 text-black" {...register('name')} />
					</div>
					<div className="mr-4">
						<span>rarity</span>
						<input className="ml-2 w-16 p-2 bg-gray-300 shadow-inner border border-gray-400 text-black" {...register('rarity')} />
					</div>
					<div onClick={handleSubmit(onSubmitAdd)} className="px-4 py-2 bg-yellow-400 text-gray-900 border border-black hover:bg-yellow-200 cursor-pointer">
						Add
					</div>
					<div onClick={toggleListView} className="ml-8 px-4 py-2 bg-blue-400 text-gray-900 border border-black hover:bg-blue-200 cursor-pointer">
						Toggle View
					</div>
					<p className="px-2 text-green-800">
						Pets Count: <span className="font-bold">{count ? count : '0'}</span>
					</p>

					{saving && <span className="px-2 text-red-600">SAVING...</span>}
				</div>
			</form>
		</>
	);
};

export default AddPet;
