import React from 'react';

import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useAuth } from '../hooks/useAuth';

const Login = () => {
	const { handleSubmit, register, errors, setError } = useForm();
	const { signInWithEmailAndPassword } = useAuth();
	const history = useHistory();

	const onSubmit = async (data) => {
		try {
			await signInWithEmailAndPassword(data.email, data.password);
			history.push('/');
		} catch (error) {
			setError('email', {
				type: 'manual',
				message: error.message,
			});
		}
	};

	return (
		<div>
			<h1>Bookshelf Tutorial</h1>
			<form onSubmit={handleSubmit(onSubmit)}>
				<input className="p-2 border border-black" name="email" type="email" placeholder="Email" {...register('email')} />
				<input className="p-2 border border-black" name="password" type="password" placeholder="Password" {...register('password')} />
				<p></p>
				<button className="p-2 px-4 bg-gray-200" type="submit">
					Login In
				</button>
			</form>

			<div>{errors && errors.email && <p>{errors.email.message}</p>}</div>
		</div>
	);
};

export default Login;
