import { getPetsSubgraph, refreshOpensea } from '../utils/opensea';

async function sleep(millis) {
	return new Promise((resolve) => setTimeout(resolve, millis));
}

function RefreshOpensea() {
	const getPets = async () => {
		let pets = await getPetsSubgraph();
		let idsToUpdate = [];
		pets.forEach((pet) => {
			idsToUpdate.push(pet.id);
		});

		console.log(idsToUpdate);
		await refreshPetMetadata(idsToUpdate);
		console.log('done');
	};

	const refreshPetMetadata = async (ids) => {
		for (let i = 0; i < ids.length; i++) {
			try {
				console.log(ids[i]);
				await refreshOpensea(ids[i], true);
				await sleep(1000);
			} catch (error) {
				console.log(error);
			}
		}
	};

	return (
		<>
			<div className="p-4">
				<button onClick={getPets}>Refresh</button>
			</div>
		</>
	);
}

export default RefreshOpensea;
