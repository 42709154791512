import { useEffect, useState } from 'react';
import { METADATA_PETS_COLLECTION } from '../../../utils/constants';
import { updateDoc } from '../../../utils/firestore';
import EditField from '../../Modals/Metadata/EditField';
import EditStats from '../Modals/EditStats';

const HorizontalBreak = () => {
	return <hr className="border border-white mt-4"></hr>;
};

const EditPetMetadata = ({ data }) => {
	const [metadata, setMetadata] = useState(undefined);
	const [isEditNameOpen, setIsEditNameOpen] = useState(false);
	const [isEditSetOpen, setIsEditSetOpen] = useState(false);
	const [isEditStatsOpen, setIsEditStatsOpen] = useState(false);

	useEffect(() => {
		if (data && data.metadata) {
			setMetadata(data.metadata);
		}
	}, [data]);

	const toggleEditName = (open) => {
		setIsEditNameOpen(open);
	};

	const handleEditName = async (_petName) => {
		if (_petName.length > 16) {
			alert('Name Too Long, max 16 chars');
		} else {
			let newDoc = data;
			newDoc.metadata.name = _petName;
			await updateDoc(METADATA_PETS_COLLECTION, newDoc, data.id);
		}
	};

	const toggleEditRarity = (open) => {
		setIsEditSetOpen(open);
	};

	const handleEditRarity = async (_rarity) => {
		let rarity = parseInt(_rarity);
		if (rarity > 0 && rarity <= 5) {
			let newDoc = data;
			let oldRarity = data.metadata.rarity;
			let mult = rarity / oldRarity;

			let atk = parseInt(data.metadata.atk * mult);
			let def = parseInt(data.metadata.def * mult);
			let total = rarity * 100;
			let spd = total - atk - def;

			newDoc.metadata.rarity = parseInt(rarity);
			newDoc.metadata.atk = atk;
			newDoc.metadata.def = def;
			newDoc.metadata.spd = spd;

			await updateDoc(METADATA_PETS_COLLECTION, newDoc, data.id);
		} else {
			alert('out of bounds');
		}
	};

	const toggleEditStats = (open) => {
		setIsEditStatsOpen(open);
	};

	const handleEditStats = async (_stats) => {
		if (_stats) {
			let newDoc = data;
			newDoc.metadata.atk = _stats.atk * newDoc.metadata.rarity;
			newDoc.metadata.def = _stats.def * newDoc.metadata.rarity;
			newDoc.metadata.spd = _stats.spd * newDoc.metadata.rarity;
			await updateDoc(METADATA_PETS_COLLECTION, newDoc, data.id);
		} else {
			alert('out of bounds');
		}
	};

	if (!metadata) {
		return <div>Loading...</div>;
	}

	return (
		<>
			<div className="p-2 mb-10 text-sm text-gray-700">
				<h4 className="text-xs my-4">Metadata</h4>
				<div className="grid grid-cols-3 items-start">
					<div>
						<h3>Pet Name:</h3>
						<p onClick={toggleEditName} className="bg-white m-2 text-lg px-2 text-gray-900 cursor-pointer">
							{metadata.name}
						</p>
					</div>
					<div>
						<h3>Rarity:</h3>
						<p onClick={toggleEditRarity} className="bg-white m-2 text-lg px-2 text-gray-900 cursor-pointer">
							{metadata.rarity.toString()}
						</p>
					</div>

					<div></div>
				</div>
				<HorizontalBreak />
				<div className="grid grid-cols-3 items-start">
					<div>
						<h3>ATTACK:</h3>
						<p className="m-2 text-2xl">{metadata.atk}</p>
						<div style={{ width: `${(metadata.atk / metadata.rarity) * 2}px`, height: '5px' }} className="bg-red-900"></div>
					</div>
					<div>
						<h3>DEFENCE:</h3>
						<p className="m-2 text-2xl">{metadata.def}</p>
						<div style={{ width: `${(metadata.def / metadata.rarity) * 2}px`, height: '5px' }} className="bg-blue-900"></div>
					</div>
					<div>
						<h3>SPEED:</h3>
						<p className="m-2 text-2xl">{metadata.spd}</p>
						<div style={{ width: `${(metadata.spd / metadata.rarity) * 2}px`, height: '5px' }} className="bg-green-900"></div>
					</div>
				</div>
				<HorizontalBreak />
				<div>
					<h3>TOTAL:</h3>
					<p className="m-2 text-2xl">{metadata.spd + metadata.atk + metadata.def}</p>
				</div>
				<div className="mt-4">
					<button onClick={toggleEditStats} className="bg-green-100 hover:bg-green-200 rounded-xl px-4 py-2 shadow">
						Edit Stat Distribution
					</button>
				</div>
			</div>
			{isEditNameOpen && <EditField field="Pet Name" toggle={toggleEditName} onSubmit={handleEditName} defaultValue={metadata.name} />}
			{isEditSetOpen && <EditField field="Rarity (1 to 5)" toggle={toggleEditRarity} onSubmit={handleEditRarity} defaultValue={metadata.rarity} />}
			{isEditStatsOpen && <EditStats data={data} toggle={toggleEditStats} onSubmit={handleEditStats} />}
		</>
	);
};

export default EditPetMetadata;
