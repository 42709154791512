import { useEffect, useState } from 'react';

const PreviousCrops = ({ data, setZoom, setCrop, cropData, reloadCrop }) => {
	const [previousCrop, setPreviousCrop] = useState(undefined);

	useEffect(() => {
		if (data && cropData && data.cropped && data.cropped[cropData.type]) {
			setPreviousCrop(data.cropped[cropData.type]);
		}
	}, [data, cropData]);

	const loadPrevious = async () => {
		if (previousCrop) {
			setCrop({ x: previousCrop.x, y: previousCrop.y });
			setZoom(previousCrop.zoom);
			reloadCrop();
		}
	};

	return (
		<div className="m-4 flext items-center space-x-2 text-sm border-b border-white pb-4">
			<span>Crop Info:</span>
			{!previousCrop && (
				<button disabled={true} className="px-4 bg-gray-100 rounded-xl cursor-default">
					No Previous Crops
				</button>
			)}
			{previousCrop && (
				<>
					<button onClick={loadPrevious} className="px-4 bg-blue-100 hover:bg-blue-200 rounded-xl shadow">
						Load Previous
					</button>
					{/* TODO */}
					{/* <button className="px-4 bg-blue-100 hover:bg-blue-200 rounded-xl shadow">Apply to All Variations</button> */}
				</>
			)}
		</div>
	);
};

export default PreviousCrops;
