import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePets } from '../../hooks/useCollections';
import PetNavbar from './PetNavbar';
import PetLargeCard from './cards/PetLargeCard';
import PetSmallCard from './cards/PetSmallCard';

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

function ListPets({ size, pets }) {
	if (!pets) {
		return null;
	}

	return (
		<div className="flex flex-wrap gap-2">
			{pets.map((nft) => {
				if (size === 'large') {
					return <PetLargeCard key={nft.baseId} nft={nft} />;
				}
				if (size === 'card') {
					return <PetSmallCard key={nft.baseId} nft={nft} />;
				}
				return <div key={nft.cmId}></div>;
			})}
		</div>
	);
}

function PetsCropPreview() {
	const { size } = useParams();

	const { pets } = usePets();
	const [croppedPets, setCroppedPets] = useState(undefined);

	const parseMetadata = (data) => {
		let element = getRandomInt(24);
		let tokenId = getRandomInt(2000);
		let nft = {
			element,
			baseId: data.metadata.baseId,
			atk: data.metadata.atk,
			def: data.metadata.def,
			spd: data.metadata.spd,
			name: data.metadata.name,
			id: tokenId,
			tokenId: tokenId,
			rarity: data.metadata.rarity,
		};

		return nft;
	};

	useEffect(() => {
		if (pets && pets.length > 0) {
			let _pet = [];
			pets.forEach((pet) => {
				if (pet.cropped) {
					_pet.push(parseMetadata(pet));
				}
			});

			setCroppedPets(_pet);
		}
	}, [pets]);

	return (
		<>
			<PetNavbar />
			<div className="flex flex-wrap">
				<ListPets pets={croppedPets} size={size} />
			</div>
		</>
	);
}

export default PetsCropPreview;
