import { useState } from 'react';

import { getCollection, getUserFromEmail, getId } from '../../utils/constants';
import ConfirmDiscord from '../Modals/ConfirmDiscord';
import { useAuth } from '../../hooks/useAuth';
import { deleteDoc, updateDoc, createDoc, doesDocExist } from '../../utils/firestore';
import Confirmation from '../Modals/Confirmation';
import { messageDiscord } from '../../utils/apiProduction';
import PreviousButton from './ActionButtons/PreviousButton';
import WaitingButton from './ActionButtons/WaitingButton';
import NotesGivenButton from './ActionButtons/NotesGivenButton';
import ReadyButton from './ActionButtons/ReadyButton';
import NextButton from './ActionButtons/NextButton';
import { metadataBase } from '../../utils/metadataUtils';

const ArtActionBar = ({ data, toggle }) => {
	const { user } = useAuth();
	const [approveColorsConfirm, setApproveColorsConfirm] = useState(0);
	const [isMessageDiscordOpen, setIsMessageDiscordOpen] = useState(false);
	const [isApproveOpen, setIsApproveOpen] = useState(false);

	const onClickApproveColors = () => {
		if (approveColorsConfirm < 1) {
			setApproveColorsConfirm(approveColorsConfirm + 1);
		}
		if (approveColorsConfirm === 1) {
			onSubmitApproveColors();
		}
	};

	const onSubmitApproveColors = async () => {
		let currentList = data.list;
		if (currentList < 4) {
			await updateDoc(getCollection(data), { list: currentList + 1, status: 0 }, getId(data));

			var discordData = {
				webhook: process.env.REACT_APP_DISCORD_ART,
				title: `${data.name} ${data.symbol}`,
				url: `https://gen2.binktogether.com/art/${data.cm_id}`,
				fieldTitle: 'SEND PSD',
				cm_id: parseInt(data.cm_id),
				fieldBody: '.',
				description: 'Colors Approved!',
				image: data.images[0],
			};

			await messageDiscord(discordData);
		}
	};

	const toggleApprove = (open) => {
		setIsApproveOpen(open);
	};

	const toggleMessageDiscord = (open) => {
		setIsMessageDiscordOpen(open);
	};

	const onSubmitOwn = async () => {
		let newDoc = data;
		if (!newDoc.artist) {
			newDoc.status = 0;
			newDoc.list = 1;
		}
		if (data.list === 0) {
			newDoc.list = 1;
		}
		newDoc.artist = getUserFromEmail(user.email);

		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const handleApprove = async () => {
		var discordData = {
			webhook: process.env.REACT_APP_DISCORD_METADATA,
			title: `${data.name} ${data.symbol}`,
			url: `https://gen2.binktogether.com/metadata/${data.cm_id}`,
			fieldTitle: 'PSD APPROVED',
			cm_id: parseInt(data.cm_id),
			fieldBody: '.',
			description: 'A Meral is ready to start METADATA! Click the link above for more details',
			image: data.images[0],
		};

		let metadata = metadataBase;
		metadata.pet = 31;
		metadata.coin = data.name;
		metadata.cmId = parseInt(data.cm_id);
		metadata.designer = `${data.designer}_${data.artist}`;
		data.metadata = metadataBase;
		data.board = 3;
		data.status = 0;
		data.list = 0;

		await createDoc('metadata', parseInt(data.cm_id), data);
		let exist = await doesDocExist('metadata', parseInt(data.cm_id));
		if (exist) {
			await messageDiscord(discordData);
			await deleteDoc('art', getId(data));
			// toggleApprove();
		}
	};

	return (
		<>
			<div style={{ backgroundColor: '#D9D9E8' }} className="flex items-center text-white text-sm p-2">
				{data.list === 0 && (
					<button onClick={onSubmitOwn} className="px-2 bg-green-600 hover:bg-green-400">
						CLICK TO START!
					</button>
				)}
				<div className="flex-grow"></div>
				{data.list > 0 && (
					<>
						<button onClick={() => toggleMessageDiscord(true)} className="px-2 bg-blue-600 hover:bg-blue-400 flex items-center mr-4">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
								/>
							</svg>
							<span className="pl-1">MESSAGE DISCORD</span>
						</button>
						<PreviousButton data={data} />
						<ReadyButton data={data} toggle={toggle} />
						<WaitingButton data={data} toggle={toggle} />
						<NotesGivenButton data={data} toggle={toggle} />
						{data.list < 3 && <NextButton data={data} />}
						{data.list === 3 && (
							<button onClick={onClickApproveColors} className="px-2 ml-4 bg-green-600 hover:bg-green-400">
								{approveColorsConfirm > 0 ? 'CONFIRM?' : 'APPROVE COLORS'}
							</button>
						)}
						{data.list === 4 && (
							<button onClick={() => toggleApprove(true)} className="px-2 ml-4 bg-green-600 hover:bg-green-400">
								PSD RECEIVED
							</button>
						)}
					</>
				)}
				{isApproveOpen && <Confirmation button="APPROVE" message={`Do you want to send ${data.symbol} into Metadata?`} toggle={toggleApprove} onSubmit={handleApprove} />}
				{isMessageDiscordOpen && <ConfirmDiscord data={data} toggle={toggleMessageDiscord} />}
			</div>
		</>
	);
};

export default ArtActionBar;
