import { useState } from 'react';
import { deleteDoc, createDoc, doesDocExist } from '../../utils/firestore';
import Confirmation from '../Modals/Confirmation';
import EditCandiate from '../Modals/EditCandiate';
import { messageDiscord } from '../../utils/apiProduction';

import CardDetail from '../CardDetail';

const CandiateActionBar = ({ data }) => {
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [isApproveOpen, setIsApproveOpen] = useState(false);
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [isDetailOpen, setIsDetailOpen] = useState(false);

	const toggleDetail = () => {
		setIsDetailOpen(!isDetailOpen);
	};

	const toggleDelete = (open) => {
		setIsDeleteOpen(open);
	};

	const toggleApprove = (open) => {
		setIsApproveOpen(open);
	};

	const toggleEdit = () => {
		setIsEditOpen(!isEditOpen);
	};

	const handleDelete = () => {
		deleteDoc('candiates', data.id);
		toggleDelete(false);
	};

	const handleApprove = async () => {
		if (data.cm_id) {
			var discordData = {
				webhook: process.env.REACT_APP_DISCORD_DESIGN,
				title: `${data.name} ${data.symbol}`,
				url: `https://gen2.binktogether.com/design/${data.cm_id}`,
				fieldTitle: 'Design Notes',
				cm_id: parseInt(data.cm_id),
				fieldBody: data.design_hints ? data.design_hints : '.',
				description: 'Meral ready to start DESIGN! Click the link above and assign',
				image: data.images[0],
			};

			data.board = 1;
			data.status = 0;
			data.list = 0;

			await createDoc('design', parseInt(data.cm_id), data);
			let exist = await doesDocExist('design', parseInt(data.cm_id));
			if (exist) {
				await messageDiscord(discordData);
				handleDelete();
				toggleApprove();
			}
		} else {
			alert('ID (from CMC) needs to be set before approval');
		}
	};

	return (
		<>
			<div style={{ backgroundColor: '#D9D9E8' }} className="flex items-center text-white text-sm p-2">
				<button onClick={() => toggleEdit(true)} className="px-2 bg-yellow-400 hover:bg-yellow-200">
					EDIT
				</button>
				<div className="flex-grow"></div>

				<button onClick={() => toggleDelete(true)} className="px-2 bg-red-400 hover:bg-red-200 mr-2">
					DELETE
				</button>
				<button onClick={() => toggleApprove(true)} className="px-2 bg-green-400 hover:bg-green-200">
					APPROVE
				</button>
			</div>

			{isDetailOpen && <CardDetail data={data} toggle={toggleDetail} />}
			{isDeleteOpen && <Confirmation button="DELETE" message={`Do you want to forever delete ${data.symbol}?`} toggle={toggleDelete} onSubmit={handleDelete} />}
			{isApproveOpen && <Confirmation button="APPROVE" message={`Do you want to send ${data.symbol} into production?`} toggle={toggleApprove} onSubmit={handleApprove} />}
			{isEditOpen && <EditCandiate toggle={toggleEdit} data={data} />}
		</>
	);
};

export default CandiateActionBar;
