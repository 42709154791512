import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { updateDoc } from '../../utils/firestore';
import { getCollection, getId } from '../../utils/constants';

import Spinner from '../Spinner';

const EditCardDetails = ({ toggle, data }) => {
	const { register, handleSubmit } = useForm();
	const [saving, setSaving] = useState(false);

	const onSubmitSave = async (doc) => {
		setSaving(true);
		await updateDoc(getCollection(data), doc, getId(data));
		toggle();
		setSaving(false);
	};

	return (
		<>
			<div className="fixed w-full h-full top-0 left-0 bg-opacity-70 bg-black"></div>
			<div style={{ width: '600px' }} className="absolute top-0 left-0">
				<div className="border-gray-400 bg-opacity-100 rounded z-30 tracking-wide shadow-xl bg-gray-900">
					<div className="text-white p-2 m-2 text-xs sm:text-base">
						<form autoComplete="off">
							<div className="flex items-center space-x-2 border-b border-white mb-4">
								<h4 className="text-xs text-gray-400">Name:</h4>
								<input disabled className="ml-2 w-36 px-2 bg-gray-900 shadow-inner" defaultValue={data.name} />
								<h4 className="text-xs text-gray-400">Symbol:</h4>
								<input disabled className="ml-2 w-16 px-2 bg-gray-900 shadow-inner" defaultValue={data.symbol} />
								<span className="text-xl">{data.cm_id && <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${data.cm_id}.png`} alt="" width="22" height="22" />}</span>
								<h4 className="text-xs text-gray-400">ID:</h4>
								<input disabled className="ml-2 w-16 px-2 bg-gray-900 shadow-inner" defaultValue={data.cm_id} />
							</div>
							<h4 className="text-xs text-gray-400 mt-4">External Link:</h4>
							<input className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('link')} defaultValue={data.link} />

							<h4 className="text-xs text-gray-400 mt-4">Reference Links:</h4>
							<textarea className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('reference_links')} defaultValue={data.reference_links} />

							<h4 className="text-xs text-gray-400 mt-4">Design Notes:</h4>
							<textarea className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('design_hints')} defaultValue={data.design_hints} />

							<h4 className="text-xs text-gray-400 mt-4">Class Ideas:</h4>
							<input className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('subclass')} defaultValue={data.subclass} />

							<h4 className="text-xs text-gray-400 mt-4">Color Ideas:</h4>
							<input className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('colors')} defaultValue={data.colors} />

							<h4 className="text-xs text-gray-400 mt-4">Pet Ideas:</h4>
							<input className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('pet')} defaultValue={data.pet} />
							<h4 className="text-xs text-gray-400 mt-4">Story / Misc Ideas:</h4>
							<textarea className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('story')} defaultValue={data.story} />
							<h4 className="text-xs text-gray-400 mt-4">Art Brief</h4>
							<textarea className="ml-2 w-full px-2 bg-gray-700 shadow-inner" {...register('art_brief')} defaultValue={data.art_brief} />
							<div className="flex items-center py-4 pb-8">
								<div className="flex-grow"></div>
								{saving && <Spinner />}
								<button onClick={toggle} className="px-4 bg-red-800 hover:bg-red-400 mx-2">
									CANCEL
								</button>
								<button onClick={handleSubmit(onSubmitSave)} className="px-4 bg-green-800 hover:bg-green-400">
									SAVE
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditCardDetails;
