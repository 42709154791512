import { useEffect, useState } from 'react';
import Images from '../../../constants/Images';
import { getPetImages } from '../utils/ImageUtils';
import { getSubclassInfo, useNFTUtils } from '../../../hooks/useNFTUtils';

const PetLargeCard = ({ nft }) => {
	const { elements } = useNFTUtils();
	const [subclassInfo, setSubclassInfo] = useState(undefined);

	let meral = {};
	meral.edition = 1;

	const colors = getPetImages(nft.baseId);

	useEffect(() => {
		if (nft) {
			setSubclassInfo(getSubclassInfo(1));
		}
	}, [nft]);

	if (!subclassInfo) {
		return <></>;
	}

	return (
		<div className="nft_details_img relative text-white m-4">
			{/* LEFT BAR */}
			<div className="p-4 w-32 z-20 absolute font-bold text-center">
				<img className="w-90 h-74 mx-auto" src={Images.logoEthem} alt="logo" />
				<p className="mt-10 text-lg border-b border-white">{`${meral.edition}/10`}</p>
				<p className="text-sm">{nft.name}</p>
				<p className="mt-5 text-sm">{elements[nft.element].element}</p>
				<p className="mt-5 text-3xl">#{nft.tokenId.toString().padStart(4, '0')}</p>
			</div>

			{/* BOTTOM BAR */}
			<div className="z-20 w-full bottom-0 absolute overflow-hidden">
				<p className="px-4 font-bold text-5xl uppercase">{nft.name}</p>
			</div>

			{/* MAIN IMAGE */}
			<div style={{ backgroundColor: elements[nft.element].color, backgroundImage: `url("${elements[nft.element].img}")` }} className="absolute bg-contain nft_details_img"></div>
			<img className="z-10 nft_details_img animate-bounceSlow absolute" src={colors.large} alt="Ethemeral Full Size" />
		</div>
	);
};

export default PetLargeCard;
