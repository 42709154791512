import { traitEyeColors, traitHairColors, traitSkinColors } from '../../../utils/metadataUtils';

const ColorButtons = ({ color, onSubmit }) => {
	return (
		<p>
			<button onClick={() => onSubmit(color)} className={`py-2 bg-gray-200 w-36 m-2 hover:bg-yellow-100`}>
				{color}
			</button>
		</p>
	);
};

const EditColors = ({ toggle, onSubmit, type }) => {
	// prettier-ignore
	let typeString = ''
	let colors = [];

	if (type === 0) {
		typeString = 'Eyes';
		colors = traitEyeColors;
	}
	if (type === 1) {
		typeString = 'Hair';
		colors = traitHairColors;
	}
	if (type === 2) {
		typeString = 'Skin';
		colors = traitSkinColors;
	}

	const handleOnSubmit = (_color) => {
		onSubmit(_color);
		toggle(false);
	};

	return (
		<>
			<div className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div style={{ height: '540px' }} className=" w-5/6 center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white">
					<div className="center w-max text-center">
						<div className="py-2">
							<p className="text-2xl">Edit {typeString}</p>
						</div>
						<div className=" grid grid-cols-4 mb-8">
							{colors.map((color, index) => (
								<ColorButtons key={index} color={color} onSubmit={handleOnSubmit} />
							))}
						</div>

						<button onClick={() => toggle(false)} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditColors;
