import React, { useState, useEffect } from 'react';
import { Lightbox } from 'react-modal-image';
import { uploadBlob } from './utils/CroppedImage';
import { updateDoc } from '../../utils/firestore';
import { getCollection, getId } from '../../utils/constants';

const Spinner = () => {
	return (
		<div className="flex justify-center mt-16">
			<svg width="48" height="48" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="red">
				<g fill="none" fillRule="evenodd">
					<g transform="translate(1 1)" strokeWidth="2">
						<circle strokeOpacity=".5" cx="18" cy="18" r="18" />
						<path d="M36 18c0-9.94-8.06-18-18-18">
							<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
						</path>
					</g>
				</g>
			</svg>
		</div>
	);
};

const ImageThumbnail = ({ url, onEnlarge }) => {
	let now = Date.now();
	return <img key={`${now}${url}`} onClick={() => onEnlarge(url)} className="cursor-pointer overflow-hidden" src={url} alt="" width="196px" height="196px" />;
};

const ImageToCrop = ({ data, url, setImage, onEnlarge, variation, suffix }) => {
	const [uploading, setUploading] = useState(false);

	const onFileChange = async (e) => {
		setUploading(true);
		try {
			const file = e.target.files[0];
			let url = await uploadBlob(file);
			let _cropped = data.cropped;
			_cropped[suffix] = url;
			await updateDoc(getCollection(data), { cropped: _cropped }, getId(data));
			setImage(url);
		} catch (error) {
			console.log(error);
		}

		setUploading(false);
	};

	return (
		<div className="bg-blue-50">
			<p>Variation: {variation}</p>
			<div style={{ width: '196px', height: '196px' }} className="bg-blue-50 border border-white relative overflow-hidden">
				{!uploading && <ImageThumbnail url={url} onEnlarge={onEnlarge} />}
				{uploading && <Spinner />}
			</div>
			<input style={{ width: '196px' }} className="ml-1 mb-1 text-xs" type="file" onChange={(e) => onFileChange(e)} />
		</div>
	);
};

const CropImages = ({ data }) => {
	const [enlarge, setEnlarge] = useState(false);
	const [enlargeUrl, setEnlargeUrl] = useState('');

	const [mainImage, setMainImage] = useState('');
	const [var1, setVar1] = useState('');
	const [var2, setVar2] = useState('');
	const [var3, setVar3] = useState('');

	const toggleEnlarge = () => {
		setEnlarge(!enlarge);
	};

	const onEnlarge = async (_url) => {
		setEnlargeUrl(_url);
		toggleEnlarge();
	};

	useEffect(() => {
		if (data && data.cropped && data.cropped.base) {
			setMainImage(data.cropped.base);
		}
		if (data && data.cropped && data.cropped.color2) {
			setVar1(data.cropped.color2);
		}
		if (data && data.cropped && data.cropped.color3) {
			setVar2(data.cropped.color3);
		}
		if (data && data.cropped && data.cropped.color4) {
			setVar3(data.cropped.color4);
		}
	}, [data]);

	return (
		<>
			<div className="flex justify-center">
				<ImageToCrop data={data} url={mainImage} setImage={setMainImage} variation="Main" suffix="base" onEnlarge={onEnlarge} />
				<ImageToCrop data={data} url={var1} setImage={setVar1} variation="2" suffix="color2" onEnlarge={onEnlarge} />
				<ImageToCrop data={data} url={var2} setImage={setVar2} variation="3" suffix="color3" onEnlarge={onEnlarge} />
				<ImageToCrop data={data} url={var3} setImage={setVar3} variation="4" suffix="color4" onEnlarge={onEnlarge} />
			</div>

			{/* <div className="overflow-hidden">
					<img onClick={onEnlargeCover} style={{ width: '400px', height: '392px' }} className="mx-auto img-preview cursor-pointer" src={coverImg} alt="" width="400" height="392" />
				</div> */}

			{enlarge && <Lightbox medium={enlargeUrl} hideDownload={true} hideZoom={true} large={enlargeUrl} alt="" onClose={toggleEnlarge} />}
		</>
	);
};

export default CropImages;
