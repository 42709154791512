import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { deleteFiles } from '../../utils/storage';

const CropNavbar = ({ all }) => {
	const [cleaning, setCleaning] = useState(false);

	const cleanUp = async () => {
		setCleaning(true);
		console.log('clean up');
		await deleteFiles();
		console.log('done');
		setCleaning(false);
	};

	return (
		<div className="bg-indigo-500 h-10 py-2 flex items-center text-xs sm:text-base text-black">
			<NavLink to="/crops" className={`${all ? 'text-white' : ''} block md:inline-block hover:text-white px-4`}>
				Crop All
			</NavLink>
			<NavLink to="/crops/large" activeClassName="menu-active" className={`block md:inline-block hover:text-white px-4`}>
				Large
			</NavLink>
			<NavLink to="/crops/card" activeClassName="menu-active" className={`block md:inline-block hover:text-white px-4`}>
				Card
			</NavLink>
			<NavLink to="/crops/inventory" activeClassName="menu-active" className={`block md:inline-block hover:text-white px-4`}>
				Inventory
			</NavLink>
			<NavLink to="/crops/thumbnail" activeClassName="menu-active" className={`block md:inline-block hover:text-white px-4`}>
				Thumbnail
			</NavLink>
			<span className="flex-grow"></span>
			<button onClick={cleanUp} className="mr-4">
				{cleaning ? 'Deleting...' : 'Delete Staging Images'}
			</button>
		</div>
	);
};

export default CropNavbar;
