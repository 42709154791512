import { getCollection, getId } from '../../../utils/constants';
import { updateDoc } from '../../../utils/firestore';

const NextButton = ({ data, limit = 10 }) => {
	const onNext = async () => {
		let currentList = data.list;
		if (currentList < limit) {
			await updateDoc(getCollection(data), { list: currentList + 1 }, getId(data));
		}
	};
	return (
		<button onClick={onNext} className="px-2 bg-gray-200 hover:bg-blue-200">
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>
		</button>
	);
};

export default NextButton;
