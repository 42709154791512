import EditPetMetadata from './EditPetMetadata';
import PetCardHeader from './petCardHeader';
import PetCardImages from './PetCardImages';

const PetEditDetail = ({ data, toggle }) => {
	return (
		<>
			<div onClick={() => toggle(false)} className="fixed w-full h-full top-0 left-0 bg-opacity-70 bg-black"></div>
			<div style={{ width: '610px', margin: '1% auto' }} className="fixed top-12 left-0 right-0 overflow-y-auto max-h-full">
				<div className="bg-gray-200 rounded pb-10">
					<div className="text-xs sm:text-base">
						<PetCardHeader data={data} />
						<PetCardImages data={data} />
					</div>
					<EditPetMetadata data={data} />
				</div>
				<div className="h-10"></div>
			</div>
		</>
	);
};

export default PetEditDetail;
