import { getCollection, getId } from '../../../utils/constants';
import { updateDoc } from '../../../utils/firestore';

const ReadyButton = ({ data, toggle }) => {
	const onReady = async () => {
		await updateDoc(getCollection(data), { status: 0 }, getId(data));
		toggle();
	};
	return (
		<button onClick={onReady} className="px-2 bg-gray-200 hover:bg-blue-200">
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="blue">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
			</svg>
		</button>
	);
};

export default ReadyButton;
