import { useState, useEffect, createContext, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [isAuthenticating, setIsAuthenticating] = useState(true);

	const signInWithEmailAndPassword = (email, password) => {
		return firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then((result) => {
				setUser(result.user);
				return true;
			});
	};

	const logout = () => {
		return firebase
			.auth()
			.signOut()
			.then(() => {
				setUser(null);
			});
	};

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			setUser(user);
			setIsAuthenticating(false);
		});

		return () => unsubscribe();
	}, []);

	const values = {
		user,
		isAuthenticating,
		logout,
		signInWithEmailAndPassword,
	};

	return <AuthContext.Provider value={values}>{!isAuthenticating && children}</AuthContext.Provider>;
};
