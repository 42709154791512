import { getCollection, getId } from '../../../utils/constants';
import { updateDoc } from '../../../utils/firestore';

const NotesGivenButton = ({ data, toggle }) => {
	const onNotesGiven = async () => {
		await updateDoc(getCollection(data), { status: 3 }, getId(data));
		toggle();
	};
	return (
		<button onClick={onNotesGiven} className="px-2 bg-gray-200 hover:bg-blue-200">
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="orange">
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
				/>
			</svg>
		</button>
	);
};

export default NotesGivenButton;
