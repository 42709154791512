import { useEffect, useState } from 'react';
import Images from '../../../constants/Images';
import { getMeralImages } from '../utils/ImageUtils';
import { getSubclassInfo, useNFTUtils } from '../../../hooks/useNFTUtils';

const MeralLarge = ({ nft, variation }) => {
	const { elements } = useNFTUtils();
	const [subclassInfo, setSubclassInfo] = useState(undefined);

	let meral = {};
	meral.edition = 1;

	const colors = getMeralImages(nft.cmId, variation);

	useEffect(() => {
		if (nft) {
			setSubclassInfo(getSubclassInfo(nft.subclass));
		}
	}, [nft]);

	if (!subclassInfo) {
		return <></>;
	}

	return (
		<div className="nft_details_bg relative text-white m-4">
			{/* LEFT BAR */}
			<div className="p-4 w-32 z-20 absolute font-bold text-center">
				<img className="w-90 h-74 mx-auto" src={Images.logoEthem} alt="logo" />
				<p className="mt-10 text-lg border-b border-white">{`${meral.edition}/10`}</p>
				<p className="text-sm">{nft.name}</p>
				<p className="mt-5 text-sm">{elements[nft.element].element}</p>
				<p className="mt-5 text-3xl">#{nft.tokenId.toString().padStart(4, '0')}</p>
			</div>

			{/* RIGHT BAR */}
			<div className="mx-2 my-4 w-64 z-20 right-0 absolute border-white border-r">
				<div className="flex items-center justify-end">
					<p className="font-bold text-right -mr-1">{nft.hp} HP</p>
					<img width="18px" height="18px" className="mx-2" src={Images.iconShield} alt="" />
				</div>
				<div className="flex items-center justify-end">
					<p className="font-bold text-right ">{nft.elf} ELF</p>
					<img width="16px" height="16px" className="mx-2" src={Images.iconDrain} alt="" />
				</div>
				<div className="flex items-center justify-end">
					<p className="font-bold text-right">{nft.xp} XP</p>
					<img width="16px" height="16px" className="mx-2" src={Images.iconDrain} alt="" />
				</div>
			</div>

			{/* BOTTOM BAR */}
			<div className="z-20 w-full bottom-0 absolute overflow-hidden">
				<p className="px-4 font-bold text-5xl uppercase">{nft.name}</p>
				<div style={{ height: '124px' }} className="bg-black pt-3 px-4">
					<div className="flex h-8">
						<div style={{ backgroundColor: `hsla(${subclassInfo.hue},100%,70%,1)` }} className="w-8">
							<img src={subclassInfo.icon} alt="subclass icon" />
						</div>
						<div className="w-48 px-2 uppercase text-lg">{subclassInfo.name}</div>
					</div>
					<div className="flex justify-between">
						<div className="">
							<div className="flex h-3 items-center mb-1 mt-2 text-sm font-bold">
								<span className="w-8 text-white">ATK</span>
								<span style={{ width: `${(nft.atk - subclassInfo.bonus.atk) * 0.4}px` }} className="h-3 bg-gray-500"></span>
								<span style={{ width: `${subclassInfo.bonus.atk * 0.4}px`, backgroundColor: `hsla(${subclassInfo.hue},100%, 70%, 1)` }} className="h-3"></span>
								<span className="pl-1 text-white">{nft.atk}</span>
							</div>
							<div className="flex h-3 items-center mb-1 text-sm font-bold">
								<span className="w-8 text-white">DEF</span>
								<span style={{ width: `${(nft.def - subclassInfo.bonus.def) * 0.4}px` }} className="h-3 bg-gray-500"></span>
								<span style={{ width: `${subclassInfo.bonus.def * 0.4}px`, backgroundColor: `hsla(${subclassInfo.hue},100%, 70%, 1)` }} className="h-3"></span>
								<span className="pl-1 text-white">{nft.def}</span>
							</div>
							<div className="flex h-3 items-center mb-1 text-sm font-bold">
								<span className="w-8 text-white">SPD</span>
								<span style={{ width: `${(nft.spd - subclassInfo.bonus.spd) * 0.4}px` }} className="h-3 bg-gray-500"></span>
								<span style={{ width: `${subclassInfo.bonus.spd * 0.4}px`, backgroundColor: `hsla(${subclassInfo.hue},100%, 70%, 1)` }} className="h-3"></span>
								<span className="pl-1 text-white">{nft.spd}</span>
							</div>
							<div className="flex h-3 items-center mb-1 text-sm font-bold">
								<span className="">TOTAL </span>
								<span className="pl-1">{parseInt(nft.spd) + parseInt(nft.def) + parseInt(nft.atk)}</span>
							</div>
						</div>
						<div className="text-sm text-right leading-relaxed text-gray-200 font-bold">
							<p>Birthed: Today</p>
							<p>Designer: {meral.artist}</p>
							<p>Minted By: 0x000000</p>
						</div>
					</div>
				</div>
			</div>

			{/* MAIN IMAGE */}
			<div style={{ backgroundColor: elements[nft.element].color, backgroundImage: `url("${elements[nft.element].img}")` }} className="absolute bg-contain nft_details_img"></div>
			<img className="z-10 nft_details_img animate-bounceSlow absolute" src={colors.large} alt="Ethemeral Full Size" />
		</div>
	);
};

export default MeralLarge;
