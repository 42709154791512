import { useState, useEffect } from 'react';
import { usePets } from '../../../hooks/useCollections';

const PetButtons = ({ pet, onSubmit }) => {
	return (
		<div onClick={() => onSubmit(pet.baseId)} className="bg-gray-200 m-2 cursor-pointer hover:shadow">
			<img className="w-20 h-20 m-auto" src={pet.image} alt="" />
			<p className="m-2">{pet.name}</p>
		</div>
	);
};
const EditPets = ({ toggle, onSubmit }) => {
	const [parsedPets, setParsedPets] = useState();
	const { pets } = usePets();

	useEffect(() => {
		if (pets) {
			let _parsedPets = [];

			pets.forEach((pet) => {
				if (pet.baseId > 30) {
					let _pet = {
						baseId: pet.baseId,
						name: pet.metadata.name,
						image: pet.images.fb_base,
					};
					if (pet.baseId === 31) {
						_pet.name = 'Random Slime';
					}
					_parsedPets.push(_pet);
				}
			});
			setParsedPets(_parsedPets);
		}
	}, [pets]);

	const handleOnSubmit = (petId) => {
		onSubmit(petId);
		toggle(false);
	};

	return (
		<>
			<div onClick={() => toggle(false)} className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div className=" w-5/6 h-5/6 center border-gray-400 bg-opacity-100 rounded overflow-y-scroll z-50 tracking-wide shadow-xl bg-white">
					<div className="mx-auto my-4 text-center">
						<div className="py-2">
							<p className="text-2xl">Assign Pet</p>
						</div>
						<div className=" grid grid-cols-7 mb-8">{parsedPets && parsedPets.map((pet, index) => <PetButtons key={index} pet={pet} onSubmit={handleOnSubmit} />)}</div>
						<button onClick={() => toggle(false)} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditPets;
