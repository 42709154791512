import { useEffect } from 'react';
import app from '../firebase';
import { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

export const useCandiates = () => {
	const [coinsCandiates, setCoins] = useState(undefined);
	const [countCandiates, setCount] = useState(0);
	const [loadingCandiates, setLoading] = useState(true);

	const [value, loading, error] = useCollection(app.firestore().collection('candiates'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	useEffect(() => {
		if (!loading && !error) {
			let allCoins = [];
			value.docs.map((doc) => {
				allCoins.push(doc.data());
				return null;
			});
			allCoins.sort(function (a, b) {
				return b.timestamp - a.timestamp;
			});
			setCoins(allCoins);
			setCount(allCoins.length);
		}
		setLoading(loading);
	}, [value, loading, error]);

	return {
		coinsCandiates,
		countCandiates,
		loadingCandiates,
	};
};

export const useDesign = () => {
	const [coinsDesign, setCoins] = useState(undefined);
	const [countDesign, setCount] = useState(0);
	const [loadingDesign, setLoading] = useState(true);

	const [value, loading, error] = useCollection(app.firestore().collection('design'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	useEffect(() => {
		if (!loading && !error) {
			let allCoins = [];
			value.docs.map((doc) => {
				allCoins.push(doc.data());
				return null;
			});
			allCoins.sort(function (a, b) {
				return b.timestamp - a.timestamp;
			});
			setCoins(allCoins);
			setCount(allCoins.length);
		}
		setLoading(loading);
	}, [value, loading, error]);

	return {
		coinsDesign,
		countDesign,
		loadingDesign,
	};
};

export const useArt = () => {
	const [coinsArt, setCoins] = useState(undefined);
	const [countArt, setCount] = useState(0);
	const [laodingArt, setLoading] = useState(true);

	const [value, loading, error] = useCollection(app.firestore().collection('art'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	useEffect(() => {
		if (!loading && !error) {
			let allCoins = [];
			value.docs.map((doc) => {
				allCoins.push(doc.data());
				return null;
			});
			allCoins.sort(function (a, b) {
				return b.timestamp - a.timestamp;
			});
			setCoins(allCoins);
			setCount(allCoins.length);
		}
		setLoading(loading);
	}, [value, loading, error]);

	return {
		coinsArt,
		countArt,
		laodingArt,
	};
};

export const useMetadata = () => {
	const [coinsMetadata, setCoins] = useState(undefined);
	const [countMetadata, setCount] = useState(0);
	const [loadingMetadata, setLoading] = useState(true);

	const [value, loading, error] = useCollection(app.firestore().collection('metadata'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	useEffect(() => {
		if (!loading && !error) {
			let allCoins = [];
			value.docs.map((doc) => {
				allCoins.push(doc.data());
				return null;
			});
			allCoins.sort(function (a, b) {
				return b.timestamp - a.timestamp;
			});
			setCoins(allCoins);
			setCount(allCoins.length);
		}
		setLoading(loading);
	}, [value, loading, error]);

	return {
		coinsMetadata,
		countMetadata,
		loadingMetadata,
	};
};

export const usePets = () => {
	const [pets, setPets] = useState(undefined);
	const [countPets, setCount] = useState(0);
	const [loadingPets, setLoading] = useState(true);

	const [value, loading, error] = useCollection(app.firestore().collection('metadata_pets'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	useEffect(() => {
		if (!loading && !error) {
			let allPets = [];

			value.docs.map((doc) => {
				allPets.push(doc.data());
				return null;
			});
			allPets.sort(function (a, b) {
				return b.baseId - a.baseId;
			});
			setPets(allPets);
			setCount(allPets.length);
		}
		setLoading(loading);
	}, [value, loading, error]);

	return {
		pets,
		countPets,
		loadingPets,
	};
};
