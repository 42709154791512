import CropImages from './CropImages';
import CardHeader from '../CardHeader';

import { useState, useEffect } from 'react';
import StartCrop from './StartCrop';
import CardImages from '../CardImages';
import { updateDoc } from '../../utils/firestore';
import { getCollection, getId } from '../../utils/constants';
import CropAndSave from './CropAndSave';

const CardDetail = ({ data, toggle }) => {
	const [saving, setSaving] = useState(false);
	const [unapproving, setUnapproving] = useState(false);
	const [cropped, setCropped] = useState(false);
	const [cropTab, setCropTab] = useState(0);

	useEffect(() => {
		if (data && data.cropped) {
			setCropped(true);
		}
		return () => {
			setCropped(undefined);
		};
	}, [data]);

	const approveCrops = async () => {
		if (data && data.cropped) {
			setSaving(true);
			let _cropped = data.cropped;
			_cropped.approved = true;
			await updateDoc(getCollection(data), { cropped: _cropped }, getId(data));

			setSaving(false);
			toggle();
		}
	};

	const unapproveCrops = async () => {
		if (data && data.cropped) {
			setUnapproving(true);
			let _cropped = data.cropped;
			_cropped.approved = false;
			await updateDoc(getCollection(data), { cropped: _cropped }, getId(data));

			setUnapproving(false);
			toggle();
		}
	};

	let largeCropData = {
		type: 'large',
		width: 1400,
		height: 1600,
		zoomDefault: 1,
		zoomMin: 0.2,
		zoomMax: 5,
		zoomStep: 0.02,
		zoomSpeed: 0.02,
		aspect: 7 / 8,
	};

	let mediumCropData = {
		type: 'preview',
		width: 256,
		height: 384,
		zoomDefault: 2,
		zoomMin: 0.2,
		zoomMax: 5,
		zoomStep: 0.05,
		zoomSpeed: 0.05,
		aspect: 2 / 3,
	};
	let thumbnailCropData = {
		type: 'thumbnail',
		width: 74,
		height: 74,
		zoomDefault: 4,
		zoomMin: 3,
		zoomMax: 6,
		zoomStep: 0.05,
		zoomSpeed: 0.1,
		aspect: 1 / 1,
	};
	let inventoryCropData = {
		type: 'inventory',
		width: 388,
		height: 112,
		zoomDefault: 1,
		zoomMin: 0.2,
		zoomMax: 5,
		zoomStep: 0.05,
		zoomSpeed: 0.1,
		aspect: 97 / 28,
	};

	return (
		<>
			<div onClick={() => toggle(false)} className="fixed w-full h-full top-0 left-0 bg-opacity-70 bg-black"></div>
			<div style={{ width: '1200px', margin: '1% auto' }} className="fixed top-12 left-0 right-0 overflow-y-auto max-h-full">
				<div className="bg-gray-200 rounded pb-10">
					<div className="text-xs sm:text-base">
						<CardHeader data={data} />
						{!cropped && (
							<>
								<div className="flex justify-center">
									<CardImages data={data} />
								</div>
								<StartCrop data={data} toggle={toggle} />
							</>
						)}
						{cropped && (
							<>
								<CropImages data={data} />

								<div className="flex justify-center mx-2 mt-10 w-full border-b-2 border-white">
									<button onClick={() => setCropTab(0)} className={`px-4 py-2 ${cropTab === 0 ? 'bg-gray-100' : 'bg-blue-200'} hover:bg-blue-400 cursor-pointer`}>
										Large
									</button>
									<button onClick={() => setCropTab(1)} className={`px-4 py-2 ${cropTab === 1 ? 'bg-gray-100' : 'bg-blue-200'} hover:bg-blue-400 cursor-pointer`}>
										Preview
									</button>
									<button onClick={() => setCropTab(2)} className={`px-4 py-2 ${cropTab === 2 ? 'bg-gray-100' : 'bg-blue-200'} hover:bg-blue-400 cursor-pointer`}>
										Inventory
									</button>
									<button onClick={() => setCropTab(3)} className={`px-4 py-2 ${cropTab === 3 ? 'bg-gray-100' : 'bg-blue-200'} hover:bg-blue-400 cursor-pointer`}>
										Thumbnail
									</button>

									{data && !data.cropped.approved && (
										<button onClick={approveCrops} className={`ml-10 text-sm px-4 py-1 mb-1 bg-green-200 hover:bg-green-400 cursor-pointer rounded-xl shadow `}>
											{saving ? 'Approving...' : 'Approve All Crops'}
										</button>
									)}

									{data && data.cropped.approved && (
										<button onClick={unapproveCrops} className={`ml-10 text-sm px-4 py-1 mb-1 bg-red-200 hover:bg-red-400 cursor-pointer rounded-xl shadow `}>
											{unapproving ? 'Unapproving...' : 'Unapprove'}
										</button>
									)}
								</div>

								{cropTab === 0 && <CropAndSave data={data} cropData={largeCropData} />}
								{cropTab === 1 && <CropAndSave data={data} cropData={mediumCropData} />}
								{cropTab === 2 && <CropAndSave data={data} cropData={inventoryCropData} />}
								{cropTab === 3 && <CropAndSave data={data} cropData={thumbnailCropData} />}
							</>
						)}
						<div className="h-44"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardDetail;
