import { useState } from 'react';
import EditCardDetails from '../Modals/EditCardDetails';

const EditDetailsBar = ({ data }) => {
	const [isEditOpen, setIsEditOpen] = useState(false);

	const toggleEdit = () => {
		setIsEditOpen(!isEditOpen);
	};

	return (
		<>
			<div style={{ backgroundColor: '#D9D9E8' }} className="flex items-center text-white text-sm p-2">
				<button onClick={() => toggleEdit(true)} className="px-2 bg-yellow-400 hover:bg-yellow-200">
					EDIT
				</button>
			</div>

			{isEditOpen && <EditCardDetails toggle={toggleEdit} data={data} />}
		</>
	);
};

export default EditDetailsBar;
