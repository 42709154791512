import axios from 'axios';

export const getCMID = async (symbol) => {
	try {
		const { data } = await axios.get(`${process.env.REACT_APP_API_PRODUCTION}getcmid/${symbol}`);
		return data;
	} catch (error) {
		console.log(error);
		return null;
	}
};

// https://api.ethemerals.com/cmc/getcmid/btc
