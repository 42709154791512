import { useState, useEffect } from 'react';
import CardDetail from './CardDetail';

const CardPreview = ({ data, queryId, listView }) => {
	const [isDetailOpen, setIsDetailOpen] = useState(false);
	const [coverImg, setCoverImg] = useState('');

	useEffect(() => {
		if (parseInt(data.cm_id) && parseInt(queryId) && parseInt(data.cm_id) === parseInt(queryId)) {
			setIsDetailOpen(true);
		}
	}, [data.cm_id, queryId]);

	useEffect(() => {
		if (data && data.images && data.images.length > 0) {
			setCoverImg(data.images[0]);
		}
	}, [data]);

	const toggleDetail = (open) => {
		setIsDetailOpen(open);
	};

	return (
		<>
			<div className=" bg-gray-100 p-2 m-2 text-xs sm:text-base w-72 shadow rounded hover:shadow-xl border border-white">
				<div onClick={() => toggleDetail(true)} className="cursor-pointer">
					{!listView && (
						<div className=" flex justify-center">
							<img className="img-crop text-center" src={coverImg} alt="" width="288" height="288" />
						</div>
					)}

					<div className="flex items-center space-x-2 mt-3">
						<span>{data.name}</span>
						<span>{data.symbol}</span>
						<span className="flex-grow"></span>
						<span>{data.cm_id && <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${data.cm_id}.png`} alt="" width="22" height="22" />}</span>
					</div>
				</div>
			</div>
			{isDetailOpen && <CardDetail data={data} toggle={toggleDetail} />}
		</>
	);
};

export default CardPreview;
