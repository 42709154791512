import { useState } from 'react';

import { useCandiates } from '../hooks/useCollections';

import AddCandiate from '../components/AddCandiate';
import CardPreview from '../components/CardPreview';

function Candiates() {
	const { coinsCandiates, loadingCandiates, countCandiates } = useCandiates();

	const [listViewActive, setListViewActive] = useState(true);

	const toggleListView = () => {
		setListViewActive(!listViewActive);
	};

	return (
		<>
			<div className="p-4">
				<AddCandiate count={countCandiates} toggleListView={toggleListView} />

				{!coinsCandiates || (loadingCandiates && <span>Loading...</span>)}
			</div>

			<div className="flex flex-wrap mx-auto justify-center">{coinsCandiates && coinsCandiates.map((coin) => <CardPreview key={coin.id} data={coin} listView={listViewActive} />)}</div>
		</>
	);
}

export default Candiates;
