import { getCollection, getId } from '../../../utils/constants';
import { updateDoc } from '../../../utils/firestore';

const PreviousButton = ({ data }) => {
	const onPrevious = async () => {
		let currentList = data.list;
		if (currentList > 0) {
			await updateDoc(getCollection(data), { list: currentList - 1 }, getId(data));
		}
	};

	return (
		<button onClick={onPrevious} className="px-2 bg-gray-200 hover:bg-blue-200">
			<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="black">
				<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
			</svg>
		</button>
	);
};

export default PreviousButton;
