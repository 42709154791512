import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Lightbox } from 'react-modal-image';
import Spinner from '../../Spinner';
import { saveFile } from '../../../utils/storage';
import { updateDoc } from '../../../utils/firestore';
import { METADATA_PETS_COLLECTION } from '../../../utils/constants';
const PetCardImages = ({ data }) => {
	const [saving, setSaving] = useState(false);
	const [enlarge, setEnlarge] = useState(false);
	const [enlargeUrl, setEnlargeUrl] = useState('');
	const [coverImg, setCoverImg] = useState('');

	const onFileChange = async (file, _data) => {
		setSaving(true);
		const url = await saveFile(file, _data.id);

		let newDoc = _data;
		newDoc.images.fb_base = url;
		await updateDoc(METADATA_PETS_COLLECTION, newDoc, _data.id);

		setSaving(false);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			onFileChange(acceptedFiles[0], data);
		},
	});

	const toggleEnlarge = () => {
		setEnlarge(!enlarge);
	};

	useEffect(() => {
		if (data && data.images.fb_base) {
			setCoverImg(data.images.fb_base);
		}
	}, [data]);

	const onEnlargeCover = async () => {
		setEnlargeUrl(coverImg);
		toggleEnlarge();
	};

	return (
		<>
			<div style={{ height: '360px' }} className="flex">
				<div style={{ width: '200px', height: '360px' }} className="bg-white ml-2">
					<section>
						<div {...getRootProps({ className: 'dropzone' })}>
							<input {...getInputProps()} />
							<div className="bg-pink-100 p-2 text-gray-600 text-sm cursor-pointer border-2 border-gray-400 border-dashed h-16">
								{saving ? (
									<div className="flex justify-center">
										<Spinner />
									</div>
								) : (
									<span>Click or Drag 'n' drop a image here</span>
								)}
							</div>
						</div>
					</section>
				</div>

				<div className="overflow-hidden">
					<img onClick={onEnlargeCover} style={{ width: '400px', height: '392px' }} className="mx-auto img-preview cursor-pointer" src={coverImg} alt="" width="400" height="392" />
				</div>
			</div>

			{enlarge && <Lightbox medium={enlargeUrl} hideDownload={true} hideZoom={true} large={enlargeUrl} alt="" onClose={toggleEnlarge} />}
		</>
	);
};

export default PetCardImages;
