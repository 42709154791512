import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMetadata } from '../hooks/useCollections';
import CropGenbar from '../components/Crops/CropGenbar';
import CropNavbar from '../components/Crops/CropNavbar';
import MeralLarge from '../components/Crops/cards/MeralLarge';
import MeralPreviewCard from '../components/Crops/cards/MeralPreviewCard';
import MeralInventory from '../components/Crops/cards/MeralInventory';
import MeralThumbnail from '../components/Crops/cards/MeralThumbnail';

function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}

function ListMerals({ size, coins, variation }) {
	if (!coins) {
		return null;
	}
	return (
		<div className="flex flex-wrap">
			{coins.map((nft) => {
				if (nft.cmId !== 0) {
					if (size === 'large') {
						return <MeralLarge key={nft.cmId} nft={nft} variation={variation} />;
					}
					if (size === 'card') {
						return <MeralPreviewCard key={nft.cmId} nft={nft} variation={variation} />;
					}
					if (size === 'inventory') {
						return <MeralInventory key={nft.cmId} nft={nft} variation={variation} />;
					}
					if (size === 'thumbnail') {
						return <MeralThumbnail key={nft.cmId} nft={nft} variation={variation} />;
					}
				}
				return <div key={nft.cmId}></div>;
			})}
		</div>
	);
}

function CropsPreview() {
	const { size, id } = useParams();
	const { coinsMetadata } = useMetadata();

	const [coinsGen2, setGen2] = useState(undefined);
	const [coinsGen1, setGen1] = useState(undefined);
	const [allCoins, setAllCoins] = useState(undefined);
	const [variation, setVariation] = useState(0);

	const parseMetadata = (data) => {
		let element = getRandomInt(24);
		let tokenId = getRandomInt(2000);
		let nft = {
			subclass: data.metadata.subclass,
			element,
			hp: 300,
			elf: 2000,
			atk: getRandomInt(1000),
			def: getRandomInt(1000),
			spd: getRandomInt(1000),
			name: data.metadata.coin,
			tokenId: tokenId,
			cmId: data.metadata.cmId,
			artist: data.metadata.artist,
		};

		return nft;
	};

	useEffect(() => {
		if (coinsMetadata && coinsMetadata.length > 0) {
			let gen2 = [];
			let gen1 = [];
			coinsMetadata.forEach((coin) => {
				if (coin.metadata.set === 2) {
					if (coin.cropped) {
						if (variation === 0) {
							gen2.push(parseMetadata(coin));
						}
						if (variation === 1) {
							if (coin.cropped.color2) {
								gen2.push(parseMetadata(coin));
							}
						}
						if (variation === 2) {
							if (coin.cropped.color3) {
								gen2.push(parseMetadata(coin));
							}
						}
						if (variation === 3) {
							if (coin.cropped.color4) {
								gen2.push(parseMetadata(coin));
							}
						}
					}
				}

				if (coin.metadata.set === 1) {
					if (variation === 0) {
						gen1.push(parseMetadata(coin));
					}
					if (variation === 1) {
						if (coin.cropped && coin.cropped.color2) {
							gen1.push(parseMetadata(coin));
						}
					}
				}
			});

			setAllCoins([gen1, gen2]);
			setGen1(gen1);
			setGen2(gen2);
		}
	}, [coinsMetadata, variation]);

	return (
		<>
			<CropNavbar />
			<CropGenbar size={size} id={id} setVariation={setVariation} />
			<div className="flex flex-wrap">
				{!id && allCoins && allCoins.length > 0 && allCoins.map((coins, index) => <ListMerals key={index} coins={coins} size={size} variation={variation} />)}
				{id === '1' && <ListMerals coins={coinsGen1} size={size} variation={variation} />}
				{id === '2' && <ListMerals coins={coinsGen2} size={size} variation={variation} />}
			</div>
		</>
	);
}

export default CropsPreview;
