import { v4 as uuidv4 } from 'uuid';

import app from '../firebase';

export const saveFile = async (file, id) => {
	let uuid = uuidv4();
	const storageRef = app.storage().ref();
	const fileRef = storageRef.child(`images/baes/${id}/${uuid}${file.name}`);
	await fileRef.put(file);
	const imageURL = await fileRef.getDownloadURL();
	return imageURL;
};

export const deleteFiles = async () => {
	const storageRef = app.storage().ref();
	const fileRef = storageRef.child(`images/baes/temp`);

	fileRef
		.listAll()
		.then((res) => {
			res.items.forEach((itemRef) => {
				itemRef
					.delete()
					.then(() => {
						console.log('deleted');
					})
					.catch((error) => {
						console.log(error, 'delete error');
					});

				// All the items under listRef.
			});
		})
		.catch((error) => {
			console.log(error);
		});
};
