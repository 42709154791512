import { useState, useEffect } from 'react';
import { useNFTUtils, getSubclassInfo } from '../../../hooks/useNFTUtils';

import Images from '../../../constants/Images';
import { getMeralImages } from '../utils/ImageUtils';

const MeralPreviewCard = ({ nft, variation }) => {
	const { elements } = useNFTUtils();
	const [subclassInfo, setSubclassInfo] = useState(undefined);

	const colors = getMeralImages(nft.cmId, variation);

	useEffect(() => {
		if (nft) {
			setSubclassInfo(getSubclassInfo(nft.subclass));
		}
	}, [nft]);

	return (
		<div
			style={{ backgroundColor: elements[nft.element].color, backgroundImage: `url("${elements[nft.element].img}")`, minWidth: '256px', maxWidth: '256px', maxHeight: '384px', minHeight: '384px' }}
			className="w-64 h-96 m-4 bg-cover relative hover:shadow-2xl hover:border-gray-100 transition duration-300"
		>
			<div className="text-xs font-bold absolute right-0 text-right text-white z-10">
				<p className=" bg-customBlue-dark rounded-l-md mt-1 px-2">{nft.hp} HP</p>
				<p className="bg-brandColor rounded-l-md mt-1 px-2">{nft.elf} ELF</p>
			</div>

			{/* MAIN IMAGE */}
			<div className="absolute top-0 left-0">{colors && <img className="" src={colors.preview} alt="" />}</div>

			{/* TOP BAR */}
			<div className="absolute flex items-center p-1 "></div>

			<div style={{ bottom: '64px' }} className="w-full absolute z-10 flex justify-end right-2 space-x-2 text-white">
				<div className="relative">
					<img width="30px" height="30px" src={Images.iconAtk} alt="" />
					<span className="center text font-black">{nft.atk}</span>
				</div>
				<div className="relative">
					<img width="30px" height="30px" src={Images.iconDef} alt="" />
					<span className="center text font-black">{nft.def}</span>
				</div>
				<div className="relative">
					<img width="30px" height="30px" src={Images.iconSpd} alt="" />
					<span className="center text font-black">{nft.spd}</span>
				</div>
			</div>

			{/* BOTTOM BAR */}

			<div style={{ backgroundColor: elements[nft.element].color1 }} className="w-full bottom-0 absolute overflow-hidden text-white">
				<div className="px-2 py-1">
					<p className="text-xs">
						#<span className="text-sm font-bold">{nft.tokenId.toString().padStart(4, '0')}</span>
					</p>
					<p className="text-2xl font-medium">{nft.name}</p>

					{subclassInfo && (
						<div style={{ backgroundColor: `hsla(${subclassInfo.hue},100%,70%,1)` }} className="flex h-6 my-1 items-center">
							<img style={{ margin: '1px 2px 1px 2px' }} width="20" height="20" src={subclassInfo.icon} alt="subclass icon" />
							<div className="w-full bg-black pl-2 uppercase font-bold text-white">{subclassInfo.name}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MeralPreviewCard;
