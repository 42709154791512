import app from '../firebase';

export const deleteDoc = async (collection, doc) => {
	try {
		await app.firestore().collection(collection).doc(doc.toString()).delete();
	} catch (error) {
		console.log(error);
	}
};

export const createDoc = async (collection, id, doc) => {
	try {
		const docRef = app.firestore().collection(collection).doc(id.toString());
		await docRef.set(doc);
	} catch (error) {
		console.log(error);
	}
};

export const doesDocExist = async (collection, id) => {
	try {
		const docRef = app.firestore().collection(collection).doc(id.toString());
		let doc = await docRef.get();
		if (doc.exists) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const updateDoc = async (collection, doc, id) => {
	try {
		const docRef = app.firestore().collection(collection).doc(id.toString());
		await docRef.update({ ...doc });
	} catch (error) {
		console.log(error);
	}
};
