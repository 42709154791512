import { useState } from 'react';

import { getCollection, getId } from '../../utils/constants';
import ConfirmDiscord from '../Modals/ConfirmDiscord';
import { updateDoc } from '../../utils/firestore';
import { messageDiscord } from '../../utils/apiProduction';
import PreviousButton from './ActionButtons/PreviousButton';

import NextButton from './ActionButtons/NextButton';
import { subclassToString } from '../../utils/metadataUtils';

const MetadataActionBar = ({ data, toggle }) => {
	const [approveMetadataConfirm, setApproveMetadataConfirm] = useState(0);
	const [isMessageDiscordOpen, setIsMessageDiscordOpen] = useState(false);

	const onClickApproveMetadata = () => {
		if (approveMetadataConfirm < 1) {
			setApproveMetadataConfirm(approveMetadataConfirm + 1);
		}
		if (approveMetadataConfirm === 1) {
			console.log('approve it');
			onSubmitApproveMetadata();
		}
	};

	const onSubmitApproveMetadata = async () => {
		let currentList = data.list;

		await updateDoc(getCollection(data), { list: currentList + 1, status: 0 }, getId(data));

		var discordData = {
			webhook: process.env.REACT_APP_DISCORD_METADATA,
			title: `${data.name} ${data.symbol}`,
			url: `https://gen2.binktogether.com/metadata/`,
			fieldTitle: `${subclassToString(data.metadata.subclass)}`,
			cm_id: parseInt(data.cm_id),
			fieldBody: `costume: ${data.metadata.costume} | eyes: ${data.metadata.eyes} | hair: ${data.metadata.hair} | skin: ${data.metadata.skin}`,
			description: `Metadata Updated!`,
			image: data.images[0],
		};
		await messageDiscord(discordData);
	};

	const toggleMessageDiscord = (open) => {
		setIsMessageDiscordOpen(open);
	};

	return (
		<>
			<div style={{ backgroundColor: '#D9D9E8' }} className="flex items-center text-white text-sm p-2">
				<div className="flex-grow"></div>

				<button onClick={() => toggleMessageDiscord(true)} className="px-2 bg-blue-600 hover:bg-blue-400 flex items-center mr-4">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
						/>
					</svg>
					<span className="pl-1">MESSAGE DISCORD</span>
				</button>
				{data.list === 0 && <NextButton data={data} />}
				{data.list >= 1 && <PreviousButton data={data} />}

				{data.list === 1 && (
					<button onClick={onClickApproveMetadata} className="px-2 ml-4 bg-green-600 hover:bg-green-400">
						{approveMetadataConfirm > 0 ? 'CONFIRM?' : 'APPROVE METADATA'}
					</button>
				)}

				{isMessageDiscordOpen && <ConfirmDiscord data={data} toggle={toggleMessageDiscord} />}
			</div>
		</>
	);
};

export default MetadataActionBar;
