import { useState } from 'react';

const Confirmation = ({ toggle, message, button, onSubmit }) => {
	const [loading, setLoading] = useState(false);
	const [clicked, setClicked] = useState(false);

	const handleOnSubmit = () => {
		setLoading(true);
		if (!clicked) {
			onSubmit();
		}
		setClicked(true);
	};

	return (
		<>
			<div className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div onClick={() => toggle(false)} className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div className=" w-5/6 h-64 center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white">
					<div className="center w-max text-center">
						<div className="py-2">
							<p className="text-2xl">ARE YOU SURE!?</p>
							<p className="py-1">{message && message}</p>
						</div>
						<button onClick={() => toggle(false)} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
						<button onClick={handleOnSubmit} className="bg-yellow-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-yellow-300 transition duration-300">
							{loading ? <span>SAVING...</span> : button}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Confirmation;
