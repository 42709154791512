import { useForm } from 'react-hook-form';

const EditField = ({ toggle, field, defaultValue = '', onSubmit }) => {
	const { register, handleSubmit } = useForm();

	const handleOnSubmit = (data) => {
		onSubmit(data.field);
		toggle();
	};

	return (
		<>
			<div className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div className=" w-5/6 h-64 center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white">
					<div className="center w-max text-center">
						<div className="py-2">
							<p className="text-2xl">Edit {field}</p>
						</div>
						<input className="text-4xl ml-2 w-full bg-yellow-100 px-2 shadow-inner" {...register('field')} defaultValue={defaultValue} />
						<button onClick={() => toggle(false)} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
						<button onClick={handleSubmit(handleOnSubmit)} className="bg-yellow-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-yellow-300 transition duration-300">
							<span>SAVE</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditField;
