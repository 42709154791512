import { useState } from 'react';
import { usePets } from '../../hooks/useCollections';

import PetNavbar from './PetNavbar';
import AddPet from './AddPet';
import PetCardPreview from './cards/PetCardPreview';

function Pets() {
	const { pets, countPets } = usePets();

	const [listViewActive, setListViewActive] = useState(true);

	const toggleListView = () => {
		setListViewActive(!listViewActive);
	};

	return (
		<>
			<PetNavbar root={true} />
			<div className="p-4">
				<AddPet count={countPets} toggleListView={toggleListView} />
			</div>

			<div className="flex flex-wrap mx-auto justify-center">{pets && pets.map((pet) => <PetCardPreview key={pet.id} data={pet} listView={listViewActive} />)}</div>
		</>
	);
}

export default Pets;
