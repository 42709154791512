import { usePets } from '../../hooks/useCollections';

import PetNavbar from './PetNavbar';
import PetCardPreview from './cards/PetCardPreview';

function PetsCrop() {
	const { pets } = usePets();

	return (
		<>
			<PetNavbar />
			<div className="p-4"></div>

			<div className="flex flex-wrap mx-auto justify-center">{pets && pets.map((pet) => <PetCardPreview key={pet.id} crop={true} data={pet} listView={false} />)}</div>
		</>
	);
}

export default PetsCrop;
