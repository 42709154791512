import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMetadata } from '../hooks/useCollections';

import CropNavbar from '../components/Crops/CropNavbar';
import List from '../components/Crops/Lists';

function CropsAll() {
	const { id } = useParams();
	const { coinsMetadata } = useMetadata();

	const [notInit, setNotInit] = useState(undefined);
	const [initialized, setInitialized] = useState(undefined);
	const [coinsGen2, setGen2] = useState(undefined);
	const [coinsGen1, setGen1] = useState(undefined);

	useEffect(() => {
		if (coinsMetadata && coinsMetadata.length > 0) {
			let _notInit = [];
			let _initialized = [];
			let gen2 = [];
			let gen1 = [];
			coinsMetadata.forEach((coin) => {
				if (coin.metadata.set === 2) {
					if (!coin.cropped) {
						_notInit.push(coin);
					}
					if (coin.cropped && !coin.cropped.approved) {
						_initialized.push(coin);
					}
					if (coin.cropped && coin.cropped.approved) {
						gen2.push(coin);
					}
				}

				if (coin.metadata.set === 1) {
					gen1.push(coin);
				}
			});

			setNotInit(_notInit);
			setInitialized(_initialized);
			setGen1(gen1);
			setGen2(gen2);
		}
	}, [coinsMetadata]);

	return (
		<>
			<CropNavbar all={true} />
			<div className="flex mt-2">
				<div>
					<List title="CHECK IMAGE AND INITIALIZE" data={notInit} queryId={id} listView={false} />
				</div>
				<div>
					<List title="CROP AND APPROVE" data={initialized} queryId={id} listView={false} />
				</div>
				<div>
					<List title="GEN2 DONE" data={coinsGen2} queryId={id} listView={true} />
				</div>
				<div>
					<List title="GEN1 DONE" data={coinsGen1} queryId={id} listView={true} />
				</div>
			</div>
		</>
	);
}

export default CropsAll;
