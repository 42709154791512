import { useState, useEffect } from 'react';
import PetCropDetail from './PetCropDetail';
import PetEditDetail from './PetEditDetail';

const PetCardPreview = ({ data, listView, crop = false }) => {
	const [isDetailOpen, setIsDetailOpen] = useState(false);
	const [isCropOpen, setIsCropOpen] = useState(false);

	const [coverImg, setCoverImg] = useState('');

	useEffect(() => {
		if (data && data.images.fb_base) {
			setCoverImg(data.images.fb_base);
		}
	}, [data]);

	const toggle = (open) => {
		if (crop) {
			setIsCropOpen(open);
		} else {
			setIsDetailOpen(open);
		}
	};

	return (
		<>
			<div className=" bg-gray-100 p-2 m-2 text-xs sm:text-base w-72 shadow rounded hover:shadow-xl border border-white">
				<div onClick={() => toggle(true)} className="cursor-pointer">
					{!listView && (
						<div className=" flex justify-center">
							<img className="img-crop text-center" src={coverImg} alt="" width="288" height="288" />
						</div>
					)}

					<div className="flex items-center space-x-2 mt-3">
						<span>{data.metadata.name}</span>
						<span className="flex-grow"></span>
						<span className="text-xs">
							rarity: <span className="text-xs bg-yellow-100 font-bold">{data.metadata.rarity}</span>
						</span>
					</div>
				</div>
			</div>

			{isDetailOpen && <PetEditDetail data={data} toggle={toggle} />}
			{isCropOpen && <PetCropDetail data={data} toggle={toggle} />}
		</>
	);
};

export default PetCardPreview;
