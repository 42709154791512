import { NavLink } from 'react-router-dom';
import { useState } from 'react';

const CropGenbar = ({ size, id, setVariation }) => {
	const [variation, setVar] = useState(0);

	const handleSetVariation = (vari) => {
		setVar(vari);
		setVariation(vari);
	};

	return (
		<div className="py-2 flex items-center text-xs font-bold text-white">
			<NavLink to={`/crops/${size}`} className={`${!id ? 'bg-red-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-red-200 px-4`}>
				ALL
			</NavLink>
			<NavLink to={`/crops/${size}/1`} className={`${id === '1' ? 'bg-red-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-red-200 px-4`}>
				GEN1
			</NavLink>
			<NavLink to={`/crops/${size}/2`} className={`${id === '2' ? 'bg-red-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-red-200 px-4`}>
				GEN2
			</NavLink>
			<span className="pl-10">Variation:</span>
			<button onClick={() => handleSetVariation(0)} className={`${variation === 0 ? 'bg-blue-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-blue-200 px-4`}>
				Base
			</button>
			<button onClick={() => handleSetVariation(1)} className={`${variation === 1 ? 'bg-blue-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-blue-200 px-4`}>
				Var1
			</button>
			<button onClick={() => handleSetVariation(2)} className={`${variation === 2 ? 'bg-blue-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-blue-200 px-4`}>
				Var2
			</button>
			<button onClick={() => handleSetVariation(3)} className={`${variation === 3 ? 'bg-blue-600' : ''} block mx-1 shadow rounded md:inline-block  hover:bg-blue-200 px-4`}>
				Var3
			</button>
			<span className="flex-grow"></span>
		</div>
	);
};

export default CropGenbar;
