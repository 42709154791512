import ReactSlider from 'react-slider';
import { useState, useEffect } from 'react';

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const EditStats = ({ toggle, onSubmit, data }) => {
	const [atk, setAtk] = useState(data.metadata.atk / data.metadata.rarity);
	const [def, setDef] = useState(data.metadata.def / data.metadata.rarity);
	const [spd, setSpd] = useState(data.metadata.spd / data.metadata.rarity);

	useEffect(() => {
		if (atk < 5) {
			setAtk(5);
		}
		if (def < 5) {
			setDef(5);
		}
		if (spd < 5) {
			setSpd(5);
		}
	}, [atk, def, spd]);

	const handleSubmit = () => {
		let _atk = clamp(atk, 5, 95);
		let _def = clamp(def, 5, 95);
		let _spd = clamp(100 - _atk - _def, 5, 95);

		let total = _atk + _def + _spd;
		let remainder = 100 - total;

		_atk += remainder;
		_atk = clamp(_atk, 5, 95);
		setAtk(_atk);

		let newStats = {
			atk: _atk,
			def: _def,
			spd: _spd,
		};

		onSubmit(newStats);
		toggle();
	};

	const atkChange = (changeEvent) => {
		setAtk(changeEvent);
		let newTotal = atk + def + spd;
		let remainder = newTotal - 100;
		let newDef = def - parseInt(remainder / 2);
		let newSpd = 100 - atk - def;
		setDef(parseInt(newDef));
		setSpd(parseInt(newSpd));
	};
	const defChange = (changeEvent) => {
		setDef(changeEvent);
		let newTotal = atk + def + spd;
		let remainder = newTotal - 100;
		let newAtk = atk - parseInt(remainder / 2);
		let newSpd = 100 - atk - def;
		setAtk(parseInt(newAtk));
		setSpd(parseInt(newSpd));
	};
	const spdChange = (changeEvent) => {
		setSpd(changeEvent);
		let newTotal = atk + def + spd;
		let remainder = newTotal - 100;
		let newAtk = atk - parseInt(remainder / 2);
		let newDef = 100 - atk - spd;
		setAtk(parseInt(newAtk));
		setDef(parseInt(newDef));
	};

	return (
		<>
			<div className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div style={{ height: '540px' }} className=" w-5/6 center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white">
					<div className="center w-max text-center">
						<div className="py-2">
							<p className="text-2xl">Edit Stat Distribution</p>
						</div>
						<div className="w-full text-left">
							<div className="w-96 p-4">
								<p>Attack</p>
								<ReactSlider
									className="w-full h-2 relative"
									trackClassName="bg-red-500 h-1 shadow"
									renderThumb={(props, state) => <div {...props} className="w-20 h-10 focus:outline-none cursor-pointer text-red-500 font-bold">{`⬆️`}</div>}
									defaultValue={atk}
									min={10}
									max={90}
									step={1}
									value={atk}
									onChange={(changeEvent) => atkChange(changeEvent)}
								/>
							</div>
							<div className="w-96 p-4">
								<p>Def</p>
								<ReactSlider
									className="w-full h-2 relative"
									trackClassName="bg-blue-500 h-1 shadow"
									renderThumb={(props, state) => <div {...props} className="w-20 h-10 focus:outline-none cursor-pointer text-blue-500 font-bold">{`⬆️`}</div>}
									defaultValue={def}
									min={10}
									max={90}
									step={1}
									value={def}
									onChange={(changeEvent) => defChange(changeEvent)}
								/>
							</div>
							<div className="w-96 p-4">
								<p>Spd</p>
								<ReactSlider
									className="w-full h-2 relative"
									trackClassName="bg-green-500 h-1 shadow"
									renderThumb={(props, state) => <div {...props} className="w-20 h-10 focus:outline-none cursor-pointer text-green-500 font-bold">{`⬆️`}</div>}
									defaultValue={spd}
									min={10}
									max={90}
									step={1}
									value={spd}
									onChange={(changeEvent) => spdChange(changeEvent)}
								/>
							</div>
						</div>

						<button onClick={() => toggle(false)} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
						<button onClick={handleSubmit} className="bg-yellow-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-yellow-300 transition duration-300">
							<span>SAVE</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditStats;
