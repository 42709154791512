import axios from 'axios';

export const messageDiscord = async (_data) => {
	try {
		const { data } = await axios.post(`${process.env.REACT_APP_API_PRODUCTION}messagediscord`, _data);
		return data;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const compressImages = async (_data) => {
	try {
		const { data } = await axios.post(`${process.env.REACT_APP_API_PRODUCTION}images/compress`, _data);
		return data;
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const resizeImages = async (_data) => {
	try {
		const { data } = await axios.post(`${process.env.REACT_APP_API_PRODUCTION}images/resize`, _data);
		return data;
	} catch (error) {
		console.log(error);
		return null;
	}
};
