const ConfirmCMID = ({ toggle, message, button, onSubmit }) => {
	return (
		<>
			<div className="center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white p-4">
				<div className="py-2">
					<p className="text-2xl">Try Get CMC ID</p>
					<p className="py-1">{message && message}</p>
				</div>
				<button onClick={toggle} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
					CANCEL
				</button>
				<button onClick={onSubmit} className="bg-yellow-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-yellow-300 transition duration-300">
					{button}
				</button>
			</div>
		</>
	);
};

export default ConfirmCMID;
