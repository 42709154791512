const BadgeBar = ({ data }) => {
	return (
		<>
			<div className="flex items-center text-white text-xs font-bold pb-2 px-2">
				{data.designer && data.designer !== 'none' && data.board > 0 && (
					<div className="px-2 rounded-lg shadow mx-1 flex items-center text-gray-600 bg-white">
						{data.designer}
						<img className="img-robot-crop" alt="avatar" src={`https://robohash.org/${data.designer}`} width="20px" height="20px" />
					</div>
				)}
				{data.artist && data.artist !== 'none' && data.board > 0 && (
					<div className="px-2 rounded-lg shadow mx-1 flex items-center text-gray-600 bg-white">
						{data.artist}
						<img className="img-robot-crop" alt="avatar" src={`https://robohash.org/${data.artist}`} width="20px" height="20px" />
					</div>
				)}

				{data.status === 0 && <div className="bg-blue-500 px-2 rounded-lg shadow mx-1">READY</div>}
				{data.status === 1 && <div className="bg-purple-500 px-2 rounded-lg shadow mx-1">WAITING FOR FEEDBACK</div>}
				{data.status === 3 && <div className="bg-yellow-400 px-2 rounded-lg shadow mx-1">NOTES GIVEN</div>}
			</div>
		</>
	);
};

export default BadgeBar;
