const PetCardHeader = ({ data }) => {
	return (
		<div className="flex items-center space-x-2 mb-2 px-2 py-1">
			<span className="text-xs text-gray-700">Name:</span>
			<span className="text-xl">{data.metadata.name}</span>
			<span className="text-xs text-gray-700">Rarity:</span>
			<span className="text-xl">{data.metadata.rarity}</span>
			<span className="flex-grow"></span>
			<span className="text-xs text-gray-700">ID:</span>
			<span className="text-xl">{data.baseId && data.baseId}</span>
		</div>
	);
};

export default PetCardHeader;
