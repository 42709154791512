import { useState, useEffect } from 'react';
import { getCollection, getId } from '../../utils/constants';
import { updateDoc } from '../../utils/firestore';

const StartCrop = ({ data, toggle }) => {
	const [saving, setSaving] = useState(false);
	const [mainImage, setMainImage] = useState(undefined);

	useEffect(() => {
		if (data && data.images && data.images.length > 0) {
			setMainImage(data.images[0]);
		}
		return () => {
			setMainImage(undefined);
		};
	}, [data]);

	const handleInit = async () => {
		setSaving(true);
		await updateFirestore(mainImage);
		setSaving(false);
		toggle();
	};

	const updateFirestore = async (url) => {
		await updateDoc(getCollection(data), { cropped: { base: url } }, getId(data));
	};

	return (
		<div className="text-center mt-20">
			<p className="my-2">Click Initialize to copy over cover image</p>
			<button onClick={handleInit} disabled={saving} className="rounded-2xl bg-blue-200 px-4 py-2 text-5xl cursor-pointer shadow-lg hover:bg-blue-400">
				{!saving ? 'Initialize' : 'Initializing...'}
			</button>
		</div>
	);
};

export default StartCrop;
