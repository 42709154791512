import { NavLink } from 'react-router-dom';

const PetNavbar = ({ root }) => {
	return (
		<div className="bg-indigo-500 h-10 py-2 flex items-center text-xs sm:text-base text-black">
			<NavLink to="/pets" className={`${root ? 'text-white' : ''} block md:inline-block hover:text-white px-4`}>
				Add & Edit
			</NavLink>
			<NavLink to="/pets/crop" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
				Crop All
			</NavLink>
			<NavLink to="/pets/preview/large" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
				Large
			</NavLink>
			<NavLink to="/pets/preview/card" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
				Card
			</NavLink>
		</div>
	);
};

export default PetNavbar;
