import { useState } from 'react';
import CardPreview from './CardPreview';

const List = ({ title, data, queryId, listView = false }) => {
	const [listViewActive, setListViewActive] = useState(listView);

	const toggleListView = () => {
		setListViewActive(!listViewActive);
	};

	return (
		<div style={{ width: '300px', minWidth: '300px' }} className="bg-gray-200 grid-rows-1 m-2 rounded shadow">
			<div className="p-2 text-sm font-bold flex items-center">
				{title}
				{data && data.length > 0 && ` (${data.length})`}
				<span className="flex-grow"></span>
				<span onClick={toggleListView} className="text-gray-400 cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
					</svg>
				</span>
			</div>
			{data && data.length > 0 && data.map((coin) => <CardPreview key={coin.cm_id} data={coin} queryId={queryId} listView={listViewActive} />)}
			<div className=" h-12"></div>
		</div>
	);
};

export default List;
