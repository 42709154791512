import { subclassToString } from '../../../utils/metadataUtils';

const SubclassButtons = ({ subclass, onSubmit }) => {
	let bgColor = '';
	if (subclass.class === 0) {
		bgColor = 'bg-blue-200';
	}
	if (subclass.class === 1) {
		bgColor = 'bg-red-200';
	}
	if (subclass.class === 2) {
		bgColor = 'bg-green-200';
	}

	return (
		<p className="">
			<button onClick={() => onSubmit(subclass)} className={`py-2 bg-gray-200 w-36 m-2 ${bgColor} hover:bg-yellow-100`}>
				{subclass.string}
			</button>
		</p>
	);
};

const EditSubclass = ({ toggle, onSubmit }) => {
	// prettier-ignore

	const warriorClasses = [
    {string: subclassToString(0), subclass: 0, class: 0},
    {string: subclassToString(1), subclass: 1, class: 0},
    {string: subclassToString(2), subclass: 2, class: 0},
    {string: subclassToString(3), subclass: 3, class: 0},
  ]
	const mageClasses = [
		{ string: subclassToString(4), subclass: 4, class: 1 },
		{ string: subclassToString(5), subclass: 5, class: 1 },
		{ string: subclassToString(6), subclass: 6, class: 1 },
		{ string: subclassToString(7), subclass: 7, class: 1 },
	];
	const rogueClasses = [
		{ string: subclassToString(8), subclass: 8, class: 2 },
		{ string: subclassToString(9), subclass: 9, class: 2 },
		{ string: subclassToString(10), subclass: 10, class: 2 },
		{ string: subclassToString(11), subclass: 11, class: 2 },
	];

	const handleOnSubmit = (subclass) => {
		onSubmit(subclass);
		toggle(false);
	};

	return (
		<>
			<div className="w-full h-full flex justify-center fixed top-0 left-0 text-black">
				<div className="fixed w-full h-full top-0 left-0 z-20 bg-opacity-40 bg-black"></div>
				<div className=" w-5/6 h-96 center border-gray-400 bg-opacity-100 rounded overflow-hidden z-50 tracking-wide shadow-xl bg-white">
					<div className="center w-max text-center">
						<div className="py-2">
							<p className="text-2xl">Edit Mainclass / Subclass</p>
						</div>
						<div className="grid grid-cols-3 items-start">
							<div>
								<h4>Warrior</h4>
								{warriorClasses.map((subclass) => (
									<SubclassButtons key={subclass.subclass} subclass={subclass} onSubmit={handleOnSubmit} />
								))}
							</div>
							<div>
								<h4>Mage</h4>
								{mageClasses.map((subclass) => (
									<SubclassButtons key={subclass.subclass} subclass={subclass} onSubmit={handleOnSubmit} />
								))}
							</div>
							<div>
								<h4>Rogue</h4>
								{rogueClasses.map((subclass) => (
									<SubclassButtons key={subclass.subclass} subclass={subclass} onSubmit={handleOnSubmit} />
								))}
							</div>
						</div>

						<button onClick={() => toggle(false)} className="bg-gray-400 text-xl text-bold px-4 py-2 my-2 shadow-lg hover:bg-gray-300 transition duration-300 mr-8">
							CANCEL
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditSubclass;
