import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Lightbox } from 'react-modal-image';
import Spinner from './Spinner';
import { saveFile } from '../utils/storage';
import { updateDoc } from '../utils/firestore';
import { getCollection, getId } from '../utils/constants';
import ImageThumbnail from './ImageThumbnail';

const CardImages = ({ data }) => {
	const [saving, setSaving] = useState(false);
	const [images, setImages] = useState([]);
	const [enlarge, setEnlarge] = useState(false);
	const [enlargeUrl, setEnlargeUrl] = useState('');
	const [coverImg, setCoverImg] = useState('');

	const onFileChange = async (file, _data) => {
		setSaving(true);

		const url = await saveFile(file, _data.id);
		let newImages = _data.images;
		newImages.unshift(url);
		let newDoc = _data;
		newDoc.images = newImages;
		await updateDoc(getCollection(_data), newDoc, getId(_data));

		setSaving(false);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			onFileChange(acceptedFiles[0], data);
		},
	});

	const toggleEnlarge = () => {
		setEnlarge(!enlarge);
	};

	const onMakeCover = async (_url) => {
		setSaving(true);
		let filtered = images.filter((url) => url !== _url);
		filtered.unshift(_url);
		let newDoc = data;
		newDoc.images = filtered;
		await updateDoc(getCollection(data), newDoc, getId(data));
		setSaving(false);
	};

	const onDelete = async (_url) => {
		setSaving(true);
		let filtered = images.filter((url) => url !== _url);
		let newImages = filtered;
		let newDoc = data;
		newDoc.images = newImages;
		await updateDoc(getCollection(data), newDoc, getId(data));
		setSaving(false);
	};

	const onEnlarge = async (_url) => {
		setEnlargeUrl(_url);
		toggleEnlarge();
	};

	useEffect(() => {
		if (data && data.images && data.images.length > 0) {
			setImages(data.images);
			setCoverImg(data.images[0]);
		}
	}, [data]);

	const onEnlargeCover = async () => {
		setEnlargeUrl(coverImg);
		toggleEnlarge();
	};

	return (
		<>
			<div style={{ height: '360px' }} className="flex">
				<div style={{ width: '200px', height: '360px' }} className="bg-white ml-2">
					<section>
						<div {...getRootProps({ className: 'dropzone' })}>
							<input {...getInputProps()} />
							<div className="bg-pink-100 p-2 text-gray-600 text-sm cursor-pointer border-2 border-gray-400 border-dashed h-16">
								{saving ? (
									<div className="flex justify-center">
										<Spinner />
									</div>
								) : (
									<span>Click or Drag 'n' drop a image here</span>
								)}
							</div>
						</div>
					</section>
					<div style={{ height: '296px' }} className="overflow-y-auto overflow-hidden">
						{images && images.length > 1 && images.map((image) => <ImageThumbnail key={image} url={image} onMakeCover={onMakeCover} onDelete={onDelete} onEnlarge={onEnlarge} />)}
						{images &&
							images.length > 0 &&
							images.length < 2 &&
							images.map((image) => <ImageThumbnail key={image} url={image} onMakeCover={onMakeCover} onDelete={onDelete} onEnlarge={onEnlarge} lastImage={true} />)}
					</div>
				</div>

				<div className="overflow-hidden">
					<img onClick={onEnlargeCover} style={{ width: '400px', height: '392px' }} className="mx-auto img-preview cursor-pointer" src={coverImg} alt="" width="400" height="392" />
				</div>
			</div>

			{enlarge && <Lightbox medium={enlargeUrl} hideDownload={true} hideZoom={true} large={enlargeUrl} alt="" onClose={toggleEnlarge} />}
		</>
	);
};

export default CardImages;
