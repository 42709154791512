import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import PrivateRoute from './PrivateRoute';
import Login from './pages/Login';
import Home from './pages/Home';
import Candiates from './pages/Candiates';
import Design from './pages/Design';
import Art from './pages/Art';
import Metadata from './pages/Metadata';

// PETS

import Layout from './components/Layout';
import CropsAll from './pages/CropsAll';
import CropsPreview from './pages/CropsPreview';
import Pets from './components/Pets/Pets';
import PetsCropPreview from './components/Pets/PetsCropPreview';
import PetsCrop from './components/Pets/PetsCrop';
import RefreshOpensea from './pages/RefreshOpensea';
import Backgrounds from './pages/Backgrounds';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			refetchInterval: 240000,
		},
	},
});

function App() {
	return (
		<Router>
			<Switch>
				<Layout>
					<Route path="/login">
						<Login />
					</Route>
					<QueryClientProvider client={queryClient}>
						{/* <ReactQueryDevtools initialIsOpen={false} /> */}
						<PrivateRoute exact path="/">
							<Home />
						</PrivateRoute>
						<PrivateRoute exact path="/candiates">
							<Candiates />
						</PrivateRoute>
						<PrivateRoute exact path="/design">
							<Design />
						</PrivateRoute>
						<PrivateRoute exact path="/design/:id">
							<Design />
						</PrivateRoute>
						<PrivateRoute exact path="/art">
							<Art />
						</PrivateRoute>
						<PrivateRoute exact path="/art/:id">
							<Art />
						</PrivateRoute>
						<PrivateRoute exact path="/metadata">
							<Metadata />
						</PrivateRoute>

						{/* CROPS */}
						<PrivateRoute exact path="/crops">
							<CropsAll />
						</PrivateRoute>
						<PrivateRoute exact path="/crops/:size">
							<CropsPreview />
						</PrivateRoute>
						<PrivateRoute exact path="/crops/:size/:id">
							<CropsPreview />
						</PrivateRoute>

						{/* PETS */}
						<PrivateRoute exact path="/pets">
							<Pets />
						</PrivateRoute>
						<PrivateRoute exact path="/pets/crop">
							<PetsCrop />
						</PrivateRoute>
						<PrivateRoute exact path="/pets/preview/:size">
							<PetsCropPreview />
						</PrivateRoute>

						{/* UTILS */}
						<PrivateRoute exact path="/backgrounds">
							<Backgrounds />
						</PrivateRoute>
						<PrivateRoute exact path="/utils">
							<RefreshOpensea />
						</PrivateRoute>
					</QueryClientProvider>
				</Layout>
			</Switch>
		</Router>
	);
}

export default App;
