import axios from 'axios';

export const refreshOpensea = async (id, pet = false) => {
	let uri = 'https://api.opensea.io/api/v1/asset/0xee8c0131aa6b66a2ce3cad6d2a039c1473a79a6d/';

	if (pet) {
		uri = 'https://api.opensea.io/api/v1/asset/0x61493114836bb14dae7a57be93de2eee95327092/'; // equipables
	}

	try {
		let url = `${uri}${id}?force_update=true`;
		const { data } = await axios.get(url);		
		return data;
	} catch (error) {
		console.log(error);
		return null;
	}
};

const subgraphEndpoint = 'https://api.thegraph.com/subgraphs/name/ethemerals/ethemerals-v2';

export const getPetsSubgraph = async () => {
	try {
		const result = await axios.post(subgraphEndpoint, {
			query: `
              {
                pets(first: 1000){
                  id
                }
              }
              `,
		});
		return result.data.data.pets;
	} catch (error) {
		return null;
	}
};
