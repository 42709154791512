import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useMetadata } from '../hooks/useCollections';

import List from '../components/Lists';

function Metadata() {
	const { id } = useParams();
	const { coinsMetadata } = useMetadata();

	const [coins0, setCoins0] = useState(undefined);
	const [coins1, setCoins1] = useState(undefined);
	const [coinsGen2, setGen2] = useState(undefined);
	const [coinsGen1, setGen1] = useState(undefined);

	useEffect(() => {
		if (coinsMetadata && coinsMetadata.length > 0) {
			let c0 = [];
			let c1 = [];
			let gen2 = [];
			let gen1 = [];
			coinsMetadata.forEach((coin) => {
				if (coin.list === 0) {
					c0.push(coin);
				}
				if (coin.list === 1) {
					c1.push(coin);
				}
				if (coin.list > 1) {
					if (coin.metadata.set === 2) {
						gen2.push(coin);
					}
					if (coin.metadata.set === 1) {
						gen1.push(coin);
					}
				}
			});

			setCoins0(c0);
			setCoins1(c1);
			setGen1(gen1);
			setGen2(gen2);
		}
	}, [coinsMetadata]);

	return (
		<>
			<div className="flex mt-2">
				<div>
					<List title="READY TO START METADATA" data={coins0} queryId={id} listView={false} />
				</div>
				<div>
					<List title="READY FOR REVIEW" data={coins1} queryId={id} />
				</div>
				<div>
					<List title="GEN2 DONE" data={coinsGen2} queryId={id} listView={true} />
				</div>
				<div>
					<List title="GEN1 DONE" data={coinsGen1} queryId={id} listView={true} />
				</div>
			</div>
		</>
	);
}

export default Metadata;
