export const getCollection = (data) => {
	let collection = '';
	if (data.board === 0) {
		collection = 'candiates';
	}
	if (data.board === 1) {
		collection = 'design';
	}
	if (data.board === 2) {
		collection = 'art';
	}
	if (data.board === 3) {
		collection = 'metadata';
	}
	return collection;
};

export const METADATA_PETS_COLLECTION = 'metadata_pets';

export const getId = (data) => {
	let id = '';
	if (getCollection(data) === 'candiates') {
		// DOES NOT HAVE CMID
		id = data.id;
	} else {
		id = data.cm_id;
	}
	return id;
};

export const getUserFromEmail = (email) => {
	if (email.length > 3) {
		return email.slice(0, 3);
	} else {
		return 'none';
	}
};
