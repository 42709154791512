const CardHeader = ({ data }) => {
	return (
		<div className="flex items-center space-x-2 mb-2 px-2 py-1">
			<span className="text-xs text-gray-700">Name:</span>
			<span className="text-xl">{data.name}</span>
			<span className="text-xs text-gray-700">Symbol:</span>
			<span className="text-xl">{data.symbol}</span>
			<span className="text-xl">{data.cm_id && <img src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${data.cm_id}.png`} alt="" width="22" height="22" />}</span>
			<span className="flex-grow"></span>
			<span className="text-xs text-gray-700">ID:</span>
			<span className="text-xl">{data.cm_id && data.cm_id}</span>
		</div>
	);
};

export default CardHeader;
