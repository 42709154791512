export const metadataBase = {
	coin: 'Bitcoin',
	cmId: 1,
	set: 2,
	class: 0,
	subclass: 0,
	costume: 'modern',
	designer: 'anom',
	eyes: 'brown',
	hair: 'brown',
	skin: 'pale',
};

export const mainclassToString = (mainclass) => {
	if (mainclass === 0) {
		return 'Warrior';
	}
	if (mainclass === 1) {
		return 'Mage';
	}
	return 'Rogue';
};

export const subclassToString = (subclass) => {
	// prettier-ignore
	const subclassStrings = [
    'Paladin',
    'Knight',
    'Dark Knight',
    'Dragoon',
    'Sorcerer',
    'Summoner',
    'Cleric',
    'Druid',
    'Ranger',
    'Berserker',
    'Assassin',
    'Monk'
  ];

	return subclassStrings[subclass];
};

// prettier-ignore
export const traitCostumes = [
  'foodie',
  'uniform',
  'sporty',
  'tribal',
  'cyborg',
  'elemental',
  'military',
  'modern',
  'casual',
  'traditional',
  'futuristic',
  'medieval',
  'formal',
  'performance',
  'dancer',
  'trendy',
  'mystic',
  'furry',
  'mascot',
  'chef',
  'android',
  'astronaut'
]

// prettier-ignore
export const traitHairColors = [
  'brown',
  'black',
  'blonde',
  'silver',
  'white',
  'orange',
  'peach',
  'red',
  'blue',
  'pastel blue',
  'purple',
  'pastel purple',
  'pink',
  'green',
  'pastel green',
  'streaks',
  'balayage',
  'mixed',
]

// prettier-ignore
export const traitEyeColors = [
  'brown',
  'black',
  'yellow',
  'silver',
  'white',
  'orange',
  'peach',
  'red',
  'blue',
  'pastel blue',
  'purple',
  'pastel purple',
  'pink',
  'green',
  'pastel green',
  'ishokushou'
]

// prettier-ignore
export const traitSkinColors = [
  'pale',
  'tanned',
  'dark',
  'metalic',
  'plastic',
  'elemental'
]
