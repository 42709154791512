import { useState } from 'react';

const ImageThumbnail = ({ url, onMakeCover, onDelete, onEnlarge, lastImage }) => {
	const [confirm, setConfirm] = useState(0);

	const onSubmitDelete = () => {
		if (confirm < 1) {
			setConfirm(confirm + 1);
		}
		if (confirm === 1) {
			onDelete(url);
		}
	};

	return (
		<div style={{ width: '200px', height: '124px' }} className="bg-blue-100 border border-white relative">
			<img onClick={() => onEnlarge(url)} className="img-thumbnail mx-auto pt-2 mb-2 cursor-pointer" src={url} alt="" width="200" height="100" />
			{!lastImage && (
				<div className="text-black text-xs flex">
					<p onClick={() => onMakeCover(url)} className="px-2 rounded hover:text-blue-500 cursor-pointer">
						Make cover
					</p>
					<p className="flex-grow"></p>
					<p onClick={onSubmitDelete} className="px-2 mr-2 rounded hover:text-blue-500 cursor-pointer">
						{confirm > 0 ? 'SURE?' : 'Delete'}
					</p>
				</div>
			)}
		</div>
	);
};

export default ImageThumbnail;
