import { useEffect, useState } from 'react';

const CardDescription = ({ data }) => {
	const [refLinks, setRefLinks] = useState([]);

	useEffect(() => {
		if (data.reference_links) {
			setRefLinks(data.reference_links.split('\n'));
		}
	}, [data]);

	return (
		<div className="p-2 ">
			<h4 className="text-xs text-gray-700 mt-4">Design Notes:</h4>
			<p>
				<span style={{ whiteSpace: 'pre-line' }}>{data.design_hints ? data.design_hints : ''}</span>
			</p>

			<h4 className="text-xs text-gray-700 mt-4">External Link:</h4>
			{data.link && (
				<a href={data.link} target="_blank" rel="noreferrer" className="bg-blue-200 cursor-pointer px-4 text-blue-800 mr-1 mb-1">
					Main Link
				</a>
			)}

			<h4 className="text-xs text-gray-700 mt-4">Reference Links:</h4>
			<div className="flex flex-wrap mx-auto">
				{refLinks &&
					refLinks.length > 0 &&
					refLinks.map((link, index) => (
						<a key={index} href={link} target="_blank" rel="noreferrer" className="bg-blue-200 cursor-pointer px-4 text-blue-800 mr-1 mb-1">
							Ref Link {index}
						</a>
					))}
			</div>

			<h4 className="text-xs text-gray-700 mt-4">Class Ideas:</h4>
			<p>{data.subclass && data.subclass}</p>

			<h4 className="text-xs text-gray-700 mt-4">Color Ideas:</h4>
			<p>{data.colors && data.colors}</p>

			<h4 className="text-xs text-gray-700 mt-4">Pet Ideas:</h4>
			<p>{data.pet && data.pet}</p>
			<h4 className="text-xs text-gray-700 mt-4">Story Ideas:</h4>
			<p>{data.story && data.story}</p>
			<h4 className="text-xs text-gray-700 mt-4">Art Brief:</h4>
			<p>{data.art_brief && data.art_brief}</p>
		</div>
	);
};

export default CardDescription;
