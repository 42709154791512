// import CropImages from '../CropImages';

import { useState, useEffect } from 'react';

import { updateDoc } from '../../../utils/firestore';
import { METADATA_PETS_COLLECTION } from '../../../utils/constants';

import PetCardHeader from './petCardHeader';
import CropAndSave from './CropAndSave';

const PetCropDetail = ({ data, toggle }) => {
	const [saving, setSaving] = useState(false);
	const [cropped, setCropped] = useState(false);
	const [cropTab, setCropTab] = useState(0);

	const mainImage = data.images.fb_base;

	useEffect(() => {
		if (data && data.cropped) {
			setCropped(true);
		}
		return () => {
			setCropped(undefined);
		};
	}, [data]);

	const handleInit = async () => {
		setSaving(true);
		await updateFirestore(mainImage);
		setSaving(false);
	};

	const updateFirestore = async (url) => {
		await updateDoc(METADATA_PETS_COLLECTION, { cropped: { base: url } }, data.id);
	};

	let largeCropData = {
		type: 'large',
		width: 1400,
		height: 1600,
		zoomDefault: 0.66,
		zoomMin: 0.2,
		zoomMax: 5,
		zoomStep: 0.02,
		zoomSpeed: 0.02,
		aspect: 7 / 8,
	};

	let mediumCropData = {
		type: 'preview',
		width: 256,
		height: 288,
		zoomDefault: 1,
		zoomMin: 0.2,
		zoomMax: 5,
		zoomStep: 0.05,
		zoomSpeed: 0.05,
		aspect: 8 / 9,
	};

	return (
		<>
			<div onClick={() => toggle(false)} className="fixed w-full h-full top-0 left-0 bg-opacity-70 bg-black"></div>
			<div style={{ width: '1200px', margin: '1% auto' }} className="fixed top-12 left-0 right-0 overflow-y-auto max-h-full">
				<div className="bg-gray-200 rounded pb-10">
					<div className="text-xs sm:text-base">
						<PetCardHeader data={data} />
						<img className="mx-auto" src={mainImage} alt="" width="200" height="200" />
						{!cropped && (
							<>
								<div className="text-center mt-20">
									<p className="my-2">No crops found, click Initialize</p>
									<button onClick={handleInit} disabled={saving} className="rounded-2xl bg-blue-200 px-4 py-2 text-5xl cursor-pointer shadow-lg hover:bg-blue-400">
										{!saving ? 'Initialize' : 'Initializing...'}
									</button>
								</div>
							</>
						)}
						{cropped && (
							<>
								<div className="flex justify-center mx-2 mt-10 w-full border-b-2 border-white">
									<button onClick={() => setCropTab(0)} className={`px-4 py-2 ${cropTab === 0 ? 'bg-gray-100' : 'bg-blue-200'} hover:bg-blue-400 cursor-pointer`}>
										Large
									</button>
									<button onClick={() => setCropTab(1)} className={`px-4 py-2 ${cropTab === 1 ? 'bg-gray-100' : 'bg-blue-200'} hover:bg-blue-400 cursor-pointer`}>
										Preview
									</button>
								</div>

								{cropTab === 0 && <CropAndSave data={data} cropData={largeCropData} />}
								{cropTab === 1 && <CropAndSave data={data} cropData={mediumCropData} />}
							</>
						)}
						<div className="h-44"></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default PetCropDetail;
