import { useNFTUtils } from '../../../hooks/useNFTUtils';
import { getMeralImages } from '../utils/ImageUtils';

const MeralThumbnail = ({ nft, variation }) => {
	const colors = getMeralImages(nft.cmId, variation);
	const { elements } = useNFTUtils();

	return (
		<div style={{ width: '74px', height: '74px', backgroundColor: elements[nft.element].color }} className="relative shadow-md cursor-pointer hover:shadow-lg">
			<img width="74" height="74" src={colors.thumbnail} alt="" />
			<span className="text-xs font-bold text-white z-10 bg-black hover:bg-red-700 bg-opacity-50 w-full absolute bottom-0 text-left">{nft.name.slice(0, 9)}</span>
		</div>
	);
};

export default MeralThumbnail;
