import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useArt } from '../hooks/useCollections';

import List from '../components/Lists';

function Art() {
	const { id } = useParams();
	const [coins0, setCoins0] = useState(undefined);
	const [coins1, setCoins1] = useState(undefined);
	const [coins2, setCoins2] = useState(undefined);
	const [coins3, setCoins3] = useState(undefined);
	const [coins4, setCoins4] = useState(undefined);

	const { coinsArt } = useArt();

	useEffect(() => {
		if (coinsArt && coinsArt.length > 0) {
			let c0 = [];
			let c1 = [];
			let c2 = [];
			let c3 = [];
			let c4 = [];
			coinsArt.forEach((coin) => {
				if (coin.list === 0) {
					c0.push(coin);
				}
				if (coin.list === 1) {
					c1.push(coin);
				}
				if (coin.list === 2) {
					c2.push(coin);
				}
				if (coin.list === 3) {
					c3.push(coin);
				}
				if (coin.list === 4) {
					c4.push(coin);
				}
			});

			setCoins0(c0);
			setCoins1(c1);
			setCoins2(c2);
			setCoins3(c3);
			setCoins4(c4);
		}
	}, [coinsArt]);

	return (
		<>
			<div className="flex mt-2">
				<div>
					<List title="BRIEFS READY TO START" data={coins0} queryId={id} />
				</div>
				<div>
					<List title="DRAFTS" data={coins1} queryId={id} />
				</div>
				<div>
					<List title="INKS" data={coins2} queryId={id} />
				</div>
				<div>
					<List title="COLORS" data={coins3} queryId={id} />
				</div>
				<div>
					<List title="APPROVED! SEND PSD" data={coins4} queryId={id} />
				</div>
			</div>
		</>
	);
}

export default Art;
