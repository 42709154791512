import { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import ReactSlider from 'react-slider';
import { resizeImages } from '../../../utils/apiProduction';
import { METADATA_PETS_COLLECTION } from '../../../utils/constants';
import { updateDoc } from '../../../utils/firestore';
import getCroppedImg, { uploadCroppedImg } from '../../Crops/utils/CroppedImage';
import PreviousCrops from '../../Crops/utils/PreviousCrops';
import Spinner from '../../Spinner';

const CropAndSave = ({ data, cropData }) => {
	const [reload, setReload] = useState(true);
	const [saving, setSaving] = useState(false);
	const [s3Url, setS3Url] = useState(undefined);

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(cropData.zoomDefault);

	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [croppedPreviewUpdated, setCroppedPreviewUpdated] = useState(false);

	const mainImage = data.images.fb_base;

	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setS3Url('');
		setCroppedAreaPixels(croppedAreaPixels);
		setCroppedPreviewUpdated(false);
	}, []);

	const showCroppedImage = async () => {
		setS3Url('');
		setSaving(true);
		try {
			const _croppedImage = await getCroppedImg(mainImage, croppedAreaPixels);
			setCroppedImage(_croppedImage);
			setCroppedPreviewUpdated(true);
		} catch (e) {
			console.error(e);
		}
		setSaving(false);
	};

	const handleUploadCroppedImage = async () => {
		setS3Url('');
		try {
			setSaving(true);
			if (mainImage) {
				const holdingURL = await uploadCroppedImg(mainImage, croppedAreaPixels);
				console.log('holdingURL', { holdingURL });

				const resizeData = {
					url: holdingURL,
					fileName: `pets/${data.baseId}_${cropData.type}.png`,
					width: cropData.width,
					height: cropData.height,
				};

				const result = await resizeImages(resizeData);
				if (result && result.data) {
					setS3Url(result.data);
				} else {
					setS3Url('Failed,try again');
				}

				let _cropped = data.cropped;
				_cropped[cropData.type] = {
					x: crop.x,
					y: crop.y,
					zoom: zoom,
				};

				await updateDoc(METADATA_PETS_COLLECTION, { cropped: _cropped }, data.id);
			}

			setSaving(false);
		} catch (e) {
			console.error(e);
		}
	};

	const reloadCrop = () => {
		setReload(false);
		setTimeout(() => {
			setReload(true);
		}, 250);
	};

	return (
		<div>
			<PreviousCrops data={data} setZoom={setZoom} setCrop={setCrop} cropData={cropData} reloadCrop={reloadCrop} />
			<div className="m-4 my-8 flex items-center space-x-2 justify-center ">
				<button className={`${croppedPreviewUpdated ? 'bg-blue-100' : 'bg-green-100 hover:bg-green-200'} rounded-xl px-4 py-2 shadow`} onClick={showCroppedImage}>
					{croppedPreviewUpdated ? 'Step1: Preview Updated' : 'Step1: Update Preview'}
				</button>

				<button className={`${!croppedPreviewUpdated ? 'bg-red-100' : 'bg-green-100 hover:bg-green-200'} rounded-xl px-4 py-2 shadow`} onClick={handleUploadCroppedImage}>
					{croppedPreviewUpdated ? 'Step2: Send to AWS!' : '<-- Preview first'}
				</button>

				<span className="mx-2 font-weight-bold text-success">{saving ? 'Saving...' : ''}</span>
				{saving && <Spinner />}
				{s3Url && <span>Saved to {s3Url}</span>}
			</div>

			<div className="m-4 flex items-center space-x-2 justify-center">
				<p>ZOOM SLIDER:</p>
				<div className="w-96">
					{reload && (
						<ReactSlider
							className="w-full h-2 relative"
							trackClassName="bg-blue-500 h-1 shadow"
							renderThumb={(props, state) => <div {...props} className="w-20 h-10 focus:outline-none cursor-pointer text-blue-500 font-bold">{`⬆️  ${state.valueNow}`}</div>}
							defaultValue={zoom}
							min={cropData.zoomMin}
							max={cropData.zoomMax}
							step={cropData.zoomStep}
							value={zoom}
							onChange={(changeEvent) => setZoom(changeEvent)}
						/>
					)}
				</div>
			</div>

			<div className="crop-wrapper flex justify-center">
				<div className={`crop-container-${cropData.type} bg-green-50`}>
					{reload && (
						<Cropper
							image={mainImage}
							minZoom={cropData.zoomMin}
							maxZoom={cropData.zoomMax}
							zoomSpeed={cropData.zoomSpeed}
							restrictPosition={false}
							crop={crop}
							zoom={zoom}
							aspect={cropData.aspect}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
							objectFit="contain"
						/>
					)}
				</div>
				{cropData.type === 'large' && (
					<div className={`crop-preview-${cropData.type} bg-gray-700`}>
						<img style={{ position: 'absolute', top: 0, left: 0 }} className={`crop-preview-${cropData.type}`} src={croppedImage} alt="" />
					</div>
				)}
				{cropData.type === 'preview' && (
					<div
						className="w-64 h-96 bg-gray-50 m-4 cursor-pointer bg-cover relative border-2 hover:shadow-2xl transition duration-300 rounded-lg"
						style={{ boderColor: 'rgb(143, 148, 163)', width: '256px', height: '384px' }}
					>
						<div className="absolute top-6 left-0">
							<img src={croppedImage} alt="" />
						</div>
						<div className="flex p-1 absolute">
							<span>
								<svg width="20" height="20" fill="gold" stroke="goldenRod" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
									></path>
								</svg>
							</span>
						</div>
						<div className="w-full h-20 bottom-0 absolute overflow-hidden">
							<div className="w-full flex items-center mb-4">
								<span className="px-1 mx-1 text-sm font-bold rounded text-white" style={{ backgroundColor: 'rgb(20, 184, 129)' }}>
									#0001
								</span>
								<div className="flex-grow"></div>
								<span className="text-xs font-bold white">STATS:</span>
								<span className="px-1 mx-1 text-sm font-bold rounded text-white" style={{ backgroundColor: 'rgb(194, 112, 126)' }}>
									17
								</span>
								<span className="px-1 text-sm font-bold rounded text-white" style={{ backgroundColor: 'rgb(126, 112, 194)' }}>
									31
								</span>
								<span className="px-1 mx-1 text-sm font-bold rounded text-white" style={{ backgroundColor: 'rgb(112, 194, 194)' }}>
									52
								</span>
							</div>
							<p className="text-center font-bold text-2xl text-black">{data.metadata.name}</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default CropAndSave;
