import CandiateActionBar from './Actions/CandiateActionBar';
import EditDetailsBar from './Actions/EditDetailsBar';
import DesignActionBar from './Actions/DesignActionsBar';
import ArtActionBar from './Actions/ArtActionBar';
import BadgeBar from './Badges/BadgeBar';
import CardDescription from './CardDescription';
import CardDescriptionArt from './CardDescriptionArt';
import CardHeader from './CardHeader';
import CardImages from './CardImages';
import CardDescriptionMetadata from './CardDescriptionMetadata';
import MetadataActionBar from './Actions/MetadataActionBar';

const CardDetail = ({ data, toggle }) => {
	return (
		<>
			<div onClick={() => toggle(false)} className="fixed w-full h-full top-0 left-0 bg-opacity-70 bg-black"></div>
			<div style={{ width: '610px', margin: '1% auto' }} className="fixed top-12 left-0 right-0 overflow-y-auto max-h-full">
				<div className="bg-gray-200 rounded pb-10">
					<div className="text-xs sm:text-base">
						<CardHeader data={data} />

						{data.board === 1 && <BadgeBar data={data} />}

						<CardImages data={data} />

						{data.board === 0 && <CandiateActionBar data={data} />}
						{data.board === 1 && <DesignActionBar data={data} toggle={() => toggle(false)} />}
						{data.board === 2 && <ArtActionBar data={data} toggle={() => toggle(false)} />}
						{data.board === 3 && <MetadataActionBar data={data} toggle={() => toggle(false)} />}
						{data.board === 1 && <EditDetailsBar data={data} />}

						{data.board < 2 && <CardDescription data={data} />}
						{data.board === 2 && <CardDescriptionArt data={data} />}
						{data.board === 3 && <CardDescriptionMetadata data={data} />}

						{data.board > 1 && data.board < 3 && <EditDetailsBar data={data} />}
					</div>
				</div>
				<div className="h-10"></div>
			</div>
		</>
	);
};

export default CardDetail;
