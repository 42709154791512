import { useState } from 'react';

import { getUserFromEmail, getCollection, getId } from '../../utils/constants';
import { useAuth } from '../../hooks/useAuth';
import ConfirmDiscord from '../Modals/ConfirmDiscord';

import { deleteDoc, updateDoc, createDoc, doesDocExist } from '../../utils/firestore';
import Confirmation from '../Modals/Confirmation';
import { messageDiscord } from '../../utils/apiProduction';
import PreviousButton from './ActionButtons/PreviousButton';
import NextButton from './ActionButtons/NextButton';
import WaitingButton from './ActionButtons/WaitingButton';
import NotesGivenButton from './ActionButtons/NotesGivenButton';
import ReadyButton from './ActionButtons/ReadyButton';

const DesignActionBar = ({ data, toggle }) => {
	const { user } = useAuth();
	const [ownConfirm, setOwnConfirm] = useState(0);
	const [disownConfirm, setDisownConfirm] = useState(0);
	const [isMessageDiscordOpen, setIsMessageDiscordOpen] = useState(false);
	const [isApproveOpen, setIsApproveOpen] = useState(false);

	const toggleApprove = (open) => {
		setIsApproveOpen(open);
	};

	const toggleMessageDiscord = (open) => {
		setIsMessageDiscordOpen(open);
	};

	const onHold = async () => {
		await updateDoc(getCollection(data), { list: 4, status: -1 }, getId(data));
	};

	const onUnHold = async () => {
		await updateDoc(getCollection(data), { list: 1, status: 0 }, getId(data));
	};

	const onClickOwn = () => {
		if (ownConfirm < 1) {
			setOwnConfirm(ownConfirm + 1);
		}
		if (ownConfirm === 1) {
			onSubmitOwn();
		}
	};

	const onClickDisown = () => {
		if (disownConfirm < 1) {
			setDisownConfirm(disownConfirm + 1);
		}
		if (disownConfirm === 1) {
			onSubmitDisown();
		}
	};

	const onSubmitOwn = async () => {
		let newDoc = data;
		if (!newDoc.designer) {
			newDoc.status = 0;
			newDoc.list = 1;
		}
		if (data.list === 0) {
			newDoc.list = 1;
		}
		newDoc.designer = getUserFromEmail(user.email);

		await updateDoc(getCollection(data), newDoc, getId(data));
	};

	const onSubmitDisown = async () => {
		await updateDoc(getCollection(data), { designer: 'none' }, getId(data));
	};

	const handleApprove = async () => {
		if (data.art_brief) {
			var discordData = {
				webhook: process.env.REACT_APP_DISCORD_ART,
				title: `${data.name} ${data.symbol}`,
				url: `https://gen2.binktogether.com/art/${data.cm_id}`,
				fieldTitle: 'Art Brief',
				cm_id: parseInt(data.cm_id),
				fieldBody: data.art_brief ? data.art_brief : '.',
				description: 'A Meral is ready to start ART! Click the link above for more details',
				image: data.images[0],
			};

			data.board = 2;
			data.status = 0;
			data.list = 0;

			await createDoc('art', parseInt(data.cm_id), data);
			let exist = await doesDocExist('art', parseInt(data.cm_id));
			if (exist) {
				await messageDiscord(discordData);
				await deleteDoc('design', getId(data));
			}
		} else {
			alert('Art Brief Needs to be set First!');
		}
		// toggleApprove();
	};

	return (
		<>
			<div style={{ backgroundColor: '#D9D9E8' }} className="flex items-center text-white text-sm p-2">
				{(data.list === 0 || !data.designer || data.designer === 'none') && (
					<button onClick={onClickOwn} className="px-2 bg-green-600 hover:bg-green-400">
						{ownConfirm > 0 ? 'CONFIRM' : 'OWN IT!'}
					</button>
				)}
				{data.list > 0 && data.designer && data.designer !== 'none' && (
					<button onClick={onClickDisown} className="px-2 bg-gray-400 hover:bg-gray-300">
						{disownConfirm > 0 ? 'CONFIRM' : 'UNASSIGN'}
					</button>
				)}
				<div className="flex-grow"></div>
				{data.list > 0 && (
					<>
						<button onClick={() => toggleMessageDiscord(true)} className="px-2 bg-blue-600 hover:bg-blue-400 flex items-center mr-4">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth={2}
									d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
								/>
							</svg>
							<span className="pl-1">MESSAGE DISCORD</span>
						</button>
						{data.list < 4 && (
							<>
								<PreviousButton data={data} />
								<ReadyButton data={data} toggle={toggle} />
								<WaitingButton data={data} toggle={toggle} />
								<NotesGivenButton data={data} toggle={toggle} />
								<button onClick={onHold} className="px-2 bg-gray-200 hover:bg-blue-200">
									<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="red">
										<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
									</svg>
								</button>
							</>
						)}

						{data.list === 4 && (
							<button onClick={onUnHold} className="px-2 bg-gray-200 hover:bg-blue-200">
								<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="green">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
								</svg>
							</button>
						)}
						{data.list < 3 && <NextButton data={data} />}
						{data.list === 3 && (
							<button onClick={() => toggleApprove(true)} className="px-2 ml-4 bg-green-600 hover:bg-green-400">
								APPROVE
							</button>
						)}
					</>
				)}
				{isApproveOpen && <Confirmation button="APPROVE" message={`Do you want to send ${data.symbol} into Art?`} toggle={toggleApprove} onSubmit={handleApprove} />}
				{isMessageDiscordOpen && <ConfirmDiscord data={data} toggle={toggleMessageDiscord} />}
			</div>
		</>
	);
};

export default DesignActionBar;
