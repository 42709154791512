import { NavLink } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const Navbar = () => {
	const { user, logout } = useAuth();

	return (
		<nav className="flex items-center justify-between flex-wrap text-indigo-400 bg-indigo-800 text-lg">
			<div className="w-full block sm:flex sm:items-center">
				<NavLink to="/candiates" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Candidates
				</NavLink>
				<NavLink to="/design" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Design
				</NavLink>
				<NavLink to="/art" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Art
				</NavLink>
				<NavLink to="/metadata" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Metadata
				</NavLink>
				<NavLink to="/crops" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Crops
				</NavLink>
				<div style={{ width: '2px' }} className="h-8 bg-indigo-600"></div>
				<NavLink to="/pets" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Pets
				</NavLink>
				<div style={{ width: '2px' }} className="h-8 bg-indigo-600"></div>
				<NavLink to="/backgrounds" activeClassName="menu-active" className={`block md:inline-block hover:text-white py-2 px-4`}>
					Backgrounds
				</NavLink>
				<div style={{ width: '2px' }} className="h-8 bg-indigo-600"></div>
				<div className="flex-grow"></div>
				{user && (
					<button onClick={logout} className="flex-none mr-4">
						Log Out
					</button>
				)}
			</div>
		</nav>
	);
};

export default Navbar;
